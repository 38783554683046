import React from "react";
import { images } from "assets/images";
import { isMobileOnly } from "react-device-detect";
import styles from "./DeviceProvider.module.scss";

type DeviceContextType = {
  isMobileOnly: boolean;
};

export const DeviceContext = React.createContext<DeviceContextType>(null);

const DeviceProvider: React.FC = ({ children }) => {
  return (
    <DeviceContext.Provider value={{ isMobileOnly }}>
      {isMobileOnly && (
        <div
          className={isMobileOnly ? styles.mobileView : styles.none}
          style={{ height: "100%" }} // this is the fix for mobile firefox browser stretching the container
          // and yes, it has to be here, in inline styles, not in css file
        >
          <img
            src={images.rotate}
            alt="Rotate phome"
            className={styles.rotateImg}
          />
          <p>Please rotate your phone to portrait view</p>
        </div>
      )}

      <div className={isMobileOnly ? styles.hiddenMobile : ""}>{children}</div>
    </DeviceContext.Provider>
  );
};

export const useDevice = () => {
  const context = React.useContext(DeviceContext);

  return context;
};

export default DeviceProvider;
