import React from "react";
import { useUser } from "modules/context/UserProvider";
import {
  useAlert,
  DefaultError,
  DefaultErrorTitle,
} from "modules/context/AlertProvider";
import { useNavigate } from "react-router-dom";
import { useProfileQuery } from "modules/utils/hooks";
import ContactUs, { ContactUsButton } from "components/Modals/ContactUs";
import Button from "components/Button";
import apiCall, { Endpoints } from "modules/api";
import { RoutesPaths } from "modules/routes";
import { images } from "assets/images";
import qs from "qs";

enum ErrorMessage {
  networkError = "Network Error",
  userNotFound = "User not found",
}

const UserNotFound = () => (
  <React.Fragment>
    <p>
      We could not identify your User ID. This is and invite-based process. To
      continue, please click on the link in the email we sent you.
    </p>
    <p>
      Please feel free to contact us using the contact button on this page or
      write to us directly at{" "}
      <a href="mailto:contact@qumata.com">contact@qumata.com</a>
    </p>
    <ContactUsButton />
  </React.Fragment>
);

const AccessDenied = () => (
  <React.Fragment>
    <p>
      Access to our website is blocked because of restricted network or device
      you are using. Please try another network or device.
    </p>
    <p>If the problem persists, please contact your IT department.</p>
  </React.Fragment>
);

const HomePage: React.FC<{}> = () => {
  const navigate = useNavigate();
  const { data: profile, isLoading: isProfileLoading } = useProfileQuery();
  const { user, pending: userPending } = useUser();
  const { show } = useAlert();

  const [showContactUs, toggleContactUs] = React.useState(false);
  const [pending, setPending] = React.useState(false);

  let isCIUser = false;

  const {
    location: { search },
  } = window;

  const query = search.replace("?", "");
  let params = qs.parse(query);

  if (
    params.ExternalBroker ||
    params.ExternalBrokerID ||
    params.ExternalID ||
    params.ProviderToken ||
    user?.redirect
  ) {
    isCIUser = true;
  }

  const handleClick = async () => {
    try {
      setPending(true);
      if (!(user && user.id)) {
        throw Error(ErrorMessage.userNotFound);
      }

      // check if user available
      await apiCall.get(Endpoints.profile, {
        headers: {
          Authorization: user?.token,
          "Content-Type": "application/json",
        },
      });

      // Check if user finished flow navigate user to thank you screen or well done screen
      let nextRoute = RoutesPaths.signIn;

      const isFinished =
        typeof profile.thank_you_screen_visited_at === "number";

      const isWellDone =
        typeof profile.waiting_data_screen_visited_at === "number";

      if (isFinished) {
        nextRoute = RoutesPaths.thankYou;
      } else if (isWellDone) {
        nextRoute = RoutesPaths.wellDone;
      }

      navigate(nextRoute);
    } catch (error) {
      let description = <DefaultError />;
      let title = DefaultErrorTitle;

      if (error.message && error.message === ErrorMessage.networkError) {
        title = "Unable to Connect";
        description = <AccessDenied />;
      }

      if (error.message && error.message === ErrorMessage.userNotFound) {
        title = "User Not Found";
        description = <UserNotFound />;
      }

      if (error.response && error.response.status === 404) {
        title = "User Not Found";
        description = <UserNotFound />;
      }

      if (error.response && error.response.status === 403) {
        title = "Unable to Connect";
        description = <AccessDenied />;
      }

      return show(title, description);
    } finally {
      setPending(false);
    }
  };

  const isLoading = pending || isProfileLoading || userPending;

  return (
    <React.Fragment>
      <div className="home">
        <div className="left-side">
          <div className="header">
            {isCIUser
              ? "Thank you for taking part in One Click"
              : "Welcome to the future of underwriting!"}
          </div>
          <div className="subheader">
            {isCIUser
              ? "We created a new alternative way to assess your health using fitness data stored in your wearables and smart devices. This assessment saves time and reduces the need to fill in lengthy questionnaires. Your daily and weekly activity data are taken on an average within 14 days. Click below and share your fitness data in just 3 minutes."
              : "Qumata uses your digital data to price your policy instead of time-consuming questionnaires or medical visits."}
          </div>
          <img
            className="logo"
            src={images.healthyHealthIllustration}
            alt="illustration"
          />
          <Button
            icon="arrowRight"
            onClick={handleClick}
            overrideTextStyle={{ paddingRight: 10 }}
            type="rounded"
            overrideButtonStyle={{
              width: "260px",
              height: "48px",
              borderRadius: "0px",
            }}
            pending={isLoading}
            disabled={isLoading}
          >
            Start
          </Button>
          <div className="subtitle">
            This might involve you to download an app.
          </div>

          {!isCIUser && (
            <div className="hint-wrapper">
              <div className="hint-line">
                <div className="hint">
                  <div className="hint-title">Secure</div>
                  <div className="hint-content">
                    Your data is only used to determine your policy price. None
                    of this information will be given to third parties.
                  </div>
                </div>
                <div className="hint">
                  <div className="hint-title">No effort, just data</div>
                  <div className="hint-content">
                    No more lengthy health questionnaires and medical visits,
                    all we need is your data. Convenience is key at qumata.{" "}
                  </div>
                </div>
              </div>
              <div className="hint-line">
                <div className="hint">
                  <div className="hint-title">Simple, but accurate</div>
                  <div className="hint-content">
                    Our algorithm compares thousands of data points to calculate
                    your personal health risk and offer you the fairest price.
                  </div>
                </div>
                <div className="hint">
                  <div className="hint-title">Focus on your needs</div>
                  <div className="hint-content">
                    Select your coverage needs, we will calculate the price.
                    Qumata will tailor the policy fee to your health risk.
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="right-side">
          <img
            className="logo"
            src={images.healthyHealthIllustration}
            alt="illustration"
          />
        </div>
      </div>
      {showContactUs && <ContactUs onClose={() => toggleContactUs(false)} />}
    </React.Fragment>
  );
};

export default HomePage;
