import React from "react";

import { images } from "assets/images";

import "./styles.scss";

interface Props {
  onContactUs?: () => void;
}

const Header: React.FunctionComponent<Props> = ({ onContactUs }) => (
  <div className="header-container">
    <div className="header-button left" />
    <div className="header-text">
      <img src={images.oneclick} alt="Header logo" className="header-logo" />
    </div>
    <div className="header-button right" onClick={onContactUs}>
      <span className="dissapearing-item">Contact Us</span>
      <img alt="contact us" src={images.helpWhite} className="image" />
    </div>
  </div>
);

export default Header;
