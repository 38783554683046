export const images = {
  appleHealthIcon: require("./apple-health.png"),
  appleHealthLogo: require("./apple-health-logo.png"),
  appleHealthTutorial1: require("./apple-health-tutorial-1.png"),
  appleHealthTutorial2: require("./apple-health-tutorial-2.png"),
  appleHealthTutorial3: require("./apple-health-tutorial-3.png"),
  appleHealthTutorial4: require("./apple-health-tutorial-4.png"),
  appleHealthTutorial2_13: require("./apple-health-tutorial-13-2.png"),
  appleHealthTutorial3_13: require("./apple-health-tutorial-13-3.png"),
  arrowLeft: require("./arrow-left.png"),
  arrowRight: require("./arrow-right.png"),
  close: require("./close-dark.png"),
  contactUs: require("./contact-us.png"),
  fitbitIcon: require("./fitbit.png"),
  fitbitLogo: require("./fitbit-logo.png"),
  garminIcon: require("./garmin.png"),
  garminLogo: require("./garmin-logo.png"),
  googleFitIcon: require("./google-fit.png"),
  googleFitLogo: require("./google-fit-logo.png"),
  healthyHealthIllustration: require("./healthy-health-illustration.png"),
  helpWhite: require("./help-white.png"),
  lock: require("./lock.png"),
  pending: require("./pending.gif"),
  pendingDots: require("./pending.png"),
  question: require("./question.png"),
  sent: require("./sent.png"),
  spinner: require("./spinner.gif"),
  oneclick: require("./oneclick.png"),
  underarmourLogo: require("./underarmourLogo.png"),
  rotate: require("./rotate.png"),
};
