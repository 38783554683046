import React from "react";
import classNames from "classnames";

import "./styles.scss";

enum TextInputType {
  dark = "dark",
  light = "light",
}

interface TextInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  disabled?: boolean;
  error?: boolean;
  helperText?: string | any;
  label?: string;
  multiline?: boolean;
  onChange?: (event: any) => void;
  placeholder?: string;
  type?: keyof typeof TextInputType;
  value?: string;
  width?: number | string;
  // use for apple health screen
  prefix?: string;
}

const TextInput: React.FunctionComponent<TextInputProps> = ({
  error,
  label,
  value,
  prefix,
  onChange,
  disabled,
  multiline,
  helperText,
  placeholder,
  width = 256,
  type = "light",
  ...props
}) => {
  const inputRef = React.createRef<HTMLInputElement>();
  const [isFocused, setIsFocused] = React.useState(false);

  const textInputStyle = classNames("text-input", type, {
    error: error,
  });

  const labelInputStyle = classNames("text-input-label", type, {
    focused: isFocused,
  });

  const helperTextStyle = classNames("text-input-helper", type, {
    error: error,
  });

  const handleFocus = (event) => {
    setIsFocused(true);

    if (props.onFocus) {
      props.onFocus(event);
    }
  };

  return (
    <div style={{ width }} className="text-input-wrapper">
      <span className={labelInputStyle}>{label}</span>
      {!!multiline && (
        <textarea
          className={textInputStyle}
          disabled={disabled}
          maxLength={200}
          onBlur={() => setIsFocused(false)}
          onChange={onChange}
          onFocus={() => setIsFocused(true)}
          placeholder={placeholder}
          rows={2}
          spellCheck={false}
          value={value}
        />
      )}
      {!multiline && (
        <div className="text-input-block">
          {prefix && (
            <span
              className="text-input text-input-prefix"
              onClick={() => inputRef.current.focus()}
            >
              {prefix}
            </span>
          )}
          <input
            ref={inputRef}
            className={textInputStyle}
            disabled={disabled}
            onBlur={() => setIsFocused(false)}
            onChange={onChange}
            onFocus={handleFocus}
            placeholder={placeholder}
            spellCheck={false}
            type="text"
            value={value}
            {...props}
          />
        </div>
      )}
      <span className={helperTextStyle}>{helperText}</span>
    </div>
  );
};

export default TextInput;
