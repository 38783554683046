import React, { useState, useEffect } from "react";
import apiCall, { Endpoints } from "modules/api";
import Bugsnag from "@bugsnag/js";

import { useUser } from "modules/context/UserProvider";
import { DeviceId } from "modules/constants";

import NotConnectedDevice from "components/NotConnectedDevice/NotConnectedDevice";
import StartConnectDevice from "components/StartConnectDevice/StartConnectDevice";
import CollectingDevice from "components/CollectingDevice/CollectingDevice";
import ConnectedDevice from "components/ConnectedDevice/ConnectedDevice";

import {
  useAlert,
  UploadingDataError,
  UploadingDataErrorTitle,
} from "modules/context/AlertProvider";
import { useCollectionStatusQuery } from "modules/utils/hooks";
import Layout from "components/Layout/Layout";
import { RoutesPaths } from "modules/routes";
import Pending from "components/Pending";

import { images } from "assets/images";
import "../styles.scss";

type ConnectDeviceProps = {
  deviceId: DeviceId;
};

export const mapIdToDevice = {
  [DeviceId.garmin]: {
    title: "Garmin",
    logo: images.garminLogo,
    redirectRoute: RoutesPaths.uploadingGarmin,
  },
  [DeviceId.fitbit]: {
    title: "Fitbit",
    logo: images.fitbitLogo,
    redirectRoute: RoutesPaths.uploadingFitbit,
  },
  [DeviceId.google]: {
    title: "Google Fit",
    logo: images.googleFitLogo,
    redirectRoute: RoutesPaths.uploadingGoogleFit,
  },
  [DeviceId.underarmour]: {
    title: "Underarmour",
    logo: images.underarmourLogo,
    redirectRoute: RoutesPaths.uploadingUnderarmour,
  },
};

const ConnectDeviceScreen: React.FunctionComponent<ConnectDeviceProps> = ({
  deviceId,
}) => {
  const { user } = useUser();
  const { show, close } = useAlert();
  const { data, isLoading: sourcePending } = useCollectionStatusQuery();

  const provider = data?.find((i) => i.name === deviceId);

  const [pending, setPending] = useState(false);

  const [tried, setTried] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (user?.id)
      setTried(!!localStorage.getItem(deviceId + "_tried" + user.id));
  }, [user, deviceId]);

  const handleConnect = async () => {
    if (!user?.token) return;
    setPending(true);

    try {
      if (!tried) {
        localStorage.setItem(deviceId + "_tried" + user.id, "true");
      }

      const headers = {
        Authorization: user?.token,
        "Content-Type": "application/json",
      };

      const { origin } = window.location;
      const redirectUrl = `${origin}${mapIdToDevice[deviceId].redirectRoute}`;

      const {
        data: { url },
      } = await apiCall.post(
        Endpoints.authProvider + deviceId,
        { redirectUrl },
        { headers },
      );

      window.open(url, "_self");
    } catch (error) {
      setPending(false);
      Bugsnag.notify(error);

      show(
        UploadingDataErrorTitle,
        <UploadingDataError onClose={() => close()} />,
      );
    }
  };

  const renderBody = () => {
    switch (true) {
      case pending || sourcePending:
        return <Pending />;

      case !provider:
        return null;

      case provider.is_receiving_data && provider.has_enough_data:
        return <ConnectedDevice device={deviceId} />;

      case provider.is_receiving_data && !provider.has_enough_data:
        return <CollectingDevice device={deviceId} />;

      case tried && !provider.is_receiving_data:
        return (
          <NotConnectedDevice device={deviceId} handleConnect={handleConnect} />
        );

      default:
        return (
          <StartConnectDevice
            device={deviceId}
            onConnect={handleConnect}
            isLoading={pending || sourcePending}
          />
        );
    }
  };

  return <Layout>{renderBody()}</Layout>;
};

export default ConnectDeviceScreen;
