import React from "react";

import DeviceDataUploading from "screens/DeviceDataUploading";
import { DeviceId } from "modules/constants";

const UnderarmourUploading: React.FunctionComponent<{}> = () => (
  <DeviceDataUploading deviceId={DeviceId.underarmour} />
);

export default UnderarmourUploading;
