import * as Yup from "yup";

export const conditions = {
  // congestiveHeartFailure: "Congestive Heart Failure",
  // periphralVascularDisease: "Periphral Vascular Disease",
  // cerebrovascularDisease: "Cerebrovascular Disease",
  // dementia: "Dementia",
  // chronicPulmonary: "Chronic Pulmonary",
  // diabetesWithoutComplication: "Diabetes without complications",
  // diabetesWithComplications: "Diabetes with complications",
  // moderateOrSevereLiverDisease: "Moderate or Severe Liver Disease",
  // mildLiverDisease: "Mild Liver Disease",
  // renalDisease: "Renal Disease",
  // metastaticCarcinoma: "Metastatic Carcinoma",
  // anyCancer: "Cancer",
  // HIVAIDS: "HIV/AIDS",
  // myorcardialInfarction: "Myocardial Infarction",
  // pepticUlcerDisease: "Peptic Ulcer Disease",
  // paraplegiaAndHemiplegia: "Paraplegia and Hemiplegia",
  // connectiveTissueDiseaseRheumaticDisease:
  //   "Connective Tissue Disease-Rheumatic Disease",
  blockedHeart: "Blocked or narrowed arteries of the heart, e.g. heart attack",
  diabetes: "Diabetes",
  cancer: "Cancer",
  none: "None of the above",
};

export const conditionsValues = {
  // congestiveHeartFailure: false,
  // periphralVascularDisease: false,
  // cerebrovascularDisease: false,
  // dementia: false,
  // chronicPulmonary: false,
  // diabetesWithoutComplication: false,
  // diabetesWithComplications: false,
  // moderateOrSevereLiverDisease: false,
  // mildLiverDisease: false,
  // renalDisease: false,
  // metastaticCarcinoma: false,
  // anyCancer: false,
  // HIVAIDS: false,
  // myorcardialInfarction: false,
  // pepticUlcerDisease: false,
  // paraplegiaAndHemiplegia: false,
  // connectiveTissueDiseaseRheumaticDisease: false,
  blockedHeart: false,
  diabetes: false,
  cancer: false,
  none: false,
};

export const profileSchema = Yup.object().shape({
  day: Yup.string().required("Enter a valid date of birth"),
  month: Yup.string().required("Enter a valid date of birth"),
  year: Yup.string().required("Enter a valid date of birth"),
  gender: Yup.string().required("Gender at Birth required"),
  weight: Yup.string().required("Weight required"),
  height: Yup.string().required("Height required"),
  smoking: Yup.string().required("Smoking status is required"),
});

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function* range(start, end, step) {
  while (start < end) {
    yield start;
    start += step;
  }
}

// stones
const createOptions = (first: number, last: number, step: number) =>
  Array.from(range(first, last, step)).map((item: number) => ({
    label: item.toString(),
    value: item.toString(),
  }));

export const stones = createOptions(7, 34, 1);
export const pounds = createOptions(0, 14, 1);

export const inchesOptions = createOptions(0, 12, 1);
export const feetOptions = createOptions(4, 7, 1);

const currentYear = new Date().getFullYear();
const maxYear = currentYear + 1 - 18;
const minYear = currentYear - 80;
export const years = Array.from(range(minYear, maxYear, 1)).reverse();

export const mapArray = (array: any[]): { label: string; value: number }[] =>
  array.map((item) => ({
    label: item.toString(),
    value: item.toString(),
  }));

export const split = (
  unitValue: string,
  isImperial: boolean,
): { integral: string; fraction: string } => {
  if (!unitValue) {
    return { integral: "0", fraction: "0" };
  }

  const value = unitValue.split(".");

  if (value.length === 1) {
    return { integral: parseInt(value[0]).toString(), fraction: "0" };
  } else if (value.length === 2) {
    const fraction = parseInt(value[1]);

    let fractionValue;
    if (isImperial) {
      fractionValue = fraction.toString() || "0";
    } else {
      fractionValue = fraction ? `0.${fraction}` : "0";
    }

    return {
      integral: parseInt(value[0]).toString(),
      fraction: fractionValue,
    };
  } else {
    return { integral: "0", fraction: "0" };
  }
};
