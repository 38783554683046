import React from "react";
import Select from "react-select";
import { FormikErrors } from "formik";

import { range, mapArray, split } from "./helper";
import { Measure } from "modules/utils/hooks";
import { InputField } from "./profile";
import FormField from "./Field";

type BaseWeightPickerProps = {
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  weight: string;
  measure: Measure;
  onFieldChange: (field: InputField, value: string | number) => void;
};

// IN KG
export const MIN_KG = 30;
export const MAX_KG = 301;
const KG_RANGE = mapArray(Array.from(range(MIN_KG, MAX_KG, 1)));
const GRAM_RANGE = mapArray(
  Array.from(range(0, 0.9, 0.1)).map((i) => parseFloat(i.toFixed(1)))
);

// IN STONES
const MIN_STONE = 4;
const MAX_STONE = 47;
const STONE_RANGE = mapArray(Array.from(range(MIN_STONE, MAX_STONE, 1)));

const MIN_FRACTION_STONE = 0;
const MAX_FRACTION_STONE = 14;
const FRACTION_STONES_RANGE = mapArray(
  Array.from(range(MIN_FRACTION_STONE, MAX_FRACTION_STONE, 1)).map((i) =>
    parseFloat(i.toFixed())
  )
);

// IN POUNDS
const MIN_POUND = 60;
const MAX_POUND = 659;
const POUNDS_RANGE = mapArray(Array.from(range(MIN_POUND, MAX_POUND + 1, 1)));

const MIN_FRACTION_POUNDS = 0;
const MAX_FRACTION_POUNDS = 0.9;
const FRACTION_POUNDS_RANGE = mapArray(
  Array.from(range(MIN_FRACTION_POUNDS, MAX_FRACTION_POUNDS, 0.1)).map((i) =>
    parseFloat(i.toFixed(1))
  )
);

export const WEIGHT_UNIT_RANGE = [
  { label: "Kilogram", value: Measure.kg },
  { label: "Stone", value: Measure.Stone },
  { label: "Pound", value: Measure.Pounds }
];

const mapRangeToMeasure = {
  [Measure.kg]: {
    range: KG_RANGE,
    decimalRange: GRAM_RANGE,
    integralMeasure: "kg",
    fractionMeasure: "g"
  },
  [Measure.Stone]: {
    range: STONE_RANGE,
    decimalRange: FRACTION_STONES_RANGE,
    integralMeasure: "st",
    fractionMeasure: "lbs"
  },
  [Measure.Pounds]: {
    range: POUNDS_RANGE,
    decimalRange: FRACTION_POUNDS_RANGE,
    integralMeasure: "lbs",
    fractionMeasure: ""
  }
};

const WeightPicker: React.FunctionComponent<BaseWeightPickerProps> = ({
  weight,
  error,
  onFieldChange,
  measure = Measure.kg
}) => {
  const isStone = measure === Measure.Stone;
  const { integral, fraction } = split(weight, isStone);

  const onNumberChange = (newNumber) =>
    isStone
      ? onFieldChange("weight", newNumber + "." + fraction)
      : onFieldChange(
          "weight",
          (parseInt(newNumber) + parseFloat(fraction)).toFixed(1)
        );

  const onDecimalChange = (newDecimal) =>
    isStone
      ? onFieldChange("weight", integral + "." + newDecimal)
      : onFieldChange(
          "weight",
          (parseInt(integral) + parseFloat(newDecimal)).toFixed(1)
        );

  const { integralMeasure, fractionMeasure, range, decimalRange } =
    mapRangeToMeasure[measure];

  const currentValue = range.find((i) => i.value === integral);
  const currentFraction = decimalRange.find((i) => i.value === fraction);

  const selectedValue = WEIGHT_UNIT_RANGE.find(
    (item) => item.value === measure
  );

  return (
    <div className="weight-picker-container">
      <FormField title="Weight unit*" className="measure-unit">
        <Select
          key={selectedValue?.label}
          placeholder="Select"
          classNamePrefix="dropdown"
          defaultValue={selectedValue}
          isSearchable={false}
          options={WEIGHT_UNIT_RANGE}
          onChange={(e) => {
            onFieldChange("weight", "");
            onFieldChange("weight_unit", e.value);
          }}
        />
      </FormField>

      <FormField title="Weight *" error={error} className="measure-selector">
        <div className="flex-row">
          <Select
            key={currentValue?.label}
            placeholder="Select"
            isSearchable={false}
            options={range}
            classNamePrefix="dropdown"
            defaultValue={currentValue}
            onChange={(e) => onNumberChange(e.value)}
          />
          <div className="measure-item">{integralMeasure}</div>

          {weight && (
            <>
              <Select
                key={currentFraction?.label}
                placeholder="Select"
                isSearchable={false}
                options={decimalRange}
                classNamePrefix="dropdown"
                defaultValue={currentFraction}
                onChange={(e) => onDecimalChange(e.value)}
              />
              <div className="decimal-item">{fractionMeasure}</div>
            </>
          )}
        </div>
      </FormField>
    </div>
  );
};
export default WeightPicker;
