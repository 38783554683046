import React from "react";
import { AccessStatus, DeviceId } from "modules/constants";

import { mapIdToDevice } from "screens/ConnectDevice";
import classes from "./DeviceHeader.module.scss";
import { images } from "assets/images";

const ConnectedDeviceHeader = ({
  device,
  status
}: {
  device: DeviceId;
  status?: AccessStatus;
}) => {
  const renderStatus = () => {
    switch (true) {
      case status === AccessStatus.loading:
        return (
          <>
            <img
              src={images.spinner}
              alt="loading"
              className={classes["loading-image"]}
            />
            <p className={classes["loading-text"]}>Connecting device</p>
          </>
        );

      case status === AccessStatus.connected:
        return (
          <p className={classes["small-loaded-text"]}>
            Data uploaded successfully!
          </p>
        );

      case status === AccessStatus.collecting:
        return (
          <p className={classes["small-loaded-text"]}>
            Connected - Uploading data
          </p>
        );

      case status === AccessStatus.notReceived:
        return (
          <p className={classes["small-loading-text-error"]}>
            Consent not received
          </p>
        );
    }
  };

  return (
    <div className={classes["header-wrapper"]}>
      <div className={classes.deviceHeaderWrapper}>
        <div className={classes.deviceHeader}>
          {mapIdToDevice[device].title} Device Connection
        </div>
        <img
          alt="logo"
          className={classes["header-logo"]}
          src={mapIdToDevice[device].logo}
        />
      </div>

      <div className={classes.loaderWrapper}>{renderStatus()}</div>
    </div>
  );
};

export default ConnectedDeviceHeader;
