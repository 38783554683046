import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Policy,
  useProfileMutation,
  useProfileQuery,
} from "modules/utils/hooks";
import CheckBox from "components/Checkbox";
import Button from "components/Button";
import {
  useAlert,
  DefaultError,
  DefaultErrorTitle,
} from "modules/context/AlertProvider";
import Pending from "components/Pending";
import Layout from "components/Layout/Layout";
import { useUser } from "modules/context/UserProvider";
import { RoutesPaths } from "modules/routes";
import "./styles.scss";
import { externalURLs } from "modules/constants";

const SignInScreen: React.FunctionComponent<{}> = () => {
  const { data: profile } = useProfileQuery();
  const { mutate } = useProfileMutation();
  const navigate = useNavigate();
  const { user } = useUser();
  const { show } = useAlert();

  const [pending, setPending] = useState(true);
  const [error, setError] = useState(false);

  const [policies, setPolicy] = useState([]);

  const canSubmit = policies?.length === 2;
  const isChecked = (policy: Policy) => policies.includes(policy);

  useEffect(() => {
    if (user && profile) {
      setPolicy(profile.policies);
      setPending(false);
    }
  }, [user, profile]);

  const updatePolicy = (policy: Policy): void => {
    const checked = policies.includes(policy);

    const data = checked
      ? policies.filter((item) => item !== policy)
      : [...policies, policy];

    if (data.length === 2) setError(false);

    setPolicy(data);
  };

  const savePolicies = async (policies: string[]) => {
    try {
      setPending(true);

      await mutate({
        policies: [Policy.privacy, Policy.terms],
      });

      setPending(false);
      navigate(RoutesPaths.chooseDevices);
    } catch (error) {
      console.log(error);
      show(DefaultErrorTitle, <DefaultError />);
    }
  };

  const submit = async () => {
    if (canSubmit) {
      await savePolicies(policies);
    } else {
      setError(true);
    }
  };

  return (
    <Layout progressBarPercent={20}>
      <div className="sign-in">
        {pending ? (
          <Pending />
        ) : (
          <>
            <div className="sign-in-header">Privacy Policy</div>

            <div className="sign-in-subscription">
              You will need to agree to our data policy and the terms and
              conditions to activate your Qumata account:
            </div>
            <div className="checkbox-container">
              <CheckBox
                value="privacy"
                checked={isChecked(Policy.privacy)}
                onChange={() => updatePolicy(Policy.privacy)}
                error={error && !isChecked(Policy.privacy)}
                label={
                  <React.Fragment>
                    <span>I agree to the Qumata </span>
                  </React.Fragment>
                }
              />
              <a
                href={externalURLs.privacyPolicy}
                target="_blank"
                rel="noreferrer"
                className="link"
              >
                Data Privacy
              </a>
            </div>
            <div className="checkbox-container">
              <CheckBox
                value="terms"
                checked={isChecked(Policy.terms)}
                onChange={() => updatePolicy(Policy.terms)}
                error={error && !isChecked(Policy.terms)}
                label={<span>I agree to the Qumata </span>}
              />
              <a
                href={externalURLs.termsAndConditions}
                target="_blank"
                rel="noreferrer"
                className="link"
              >
                Terms of Use
              </a>
            </div>
            {error && (
              <div className="error-message">
                You must agree to our Privacy Policy and Terms and Conditions to
                continue.
              </div>
            )}
            <div className="sign-in-buttons-wrapper">
              <Button icon="arrowRight" onClick={submit} type="rounded">
                Continue
              </Button>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default SignInScreen;
