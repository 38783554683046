import { config, DeviceId } from "modules/constants";
import { useUser } from "modules/context/UserProvider";
import { useMutation, useQuery, useQueryClient } from "react-query";
import apiCall, { Endpoints } from "modules/api";
import qs from "qs";

export enum Policy {
  privacy = "privacy",
  terms = "terms",
}

export enum Measure {
  Centimeters = "cm",
  Feet = "ft",
  Stone = "st_lb",
  Pounds = "lb",
  kg = "kg",
}

type PryvProfileEvent = {
  weight?: string;
  height?: string;
  gender?: string;
  dob?: string;
  smoking?: string;
  policies?: Policy[];
  conditions?: string[];
};

type PartialProfile = Partial<PryvProfileEvent>;
type MutateProfileProps = {
  /** user token */
  token?: string;
  data: PartialProfile;
};

const defaultErrorMessage = "Invalid authorization token";
export const profileQueryKey = "profile";

const fetchProfile = async (token: string) => {
  const url = config.api + Endpoints.profile;
  const response = await apiCall.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });

  return response.data;
};

export function useRefetchProfileQuery() {
  const { user } = useUser();

  return useQuery(
    [profileQueryKey, user?.token],
    () => fetchProfile(user?.token),
    { refetchInterval: 5000, enabled: !!user?.redirect },
  );
}

export function useProfileQuery() {
  const { user } = useUser();

  return useQuery(
    [profileQueryKey, user?.token],
    () => fetchProfile(user?.token),
    {
      enabled: !!user?.id,
      retry: 0,
    },
  );
}

export const mutateProfile = async ({ token, data }: MutateProfileProps) => {
  if (typeof token !== "string") throw new Error(defaultErrorMessage);

  const response = await apiCall.put(Endpoints.profile, JSON.stringify(data), {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });

  // @ts-ignore
  if (!response.ok) throw new Error(json.error?.message ?? defaultErrorMessage);

  return response.data;
};

export function useProfileMutation() {
  const queryClient = useQueryClient();
  const { user } = useUser();

  return useMutation(
    async (data: PartialProfile) =>
      await mutateProfile({ token: user?.token, data }),
    {
      onMutate: (state) => {
        queryClient.cancelQueries(profileQueryKey);

        return queryClient.setQueryData(profileQueryKey, (old) => {
          // @ts-ignore
          return { ...old, ...state };
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(profileQueryKey);
      },
      onSuccess: (data) => {
        const oldState =
          queryClient.getQueryData<PartialProfile>(profileQueryKey) || {};

        queryClient.setQueryData(profileQueryKey, { ...oldState, ...data });
      },
    },
  );
}

/** Status Collection API */
export const fetchStatus = async (token: string, url: string) => {
  const response = await apiCall.get(url, {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  });

  return response.data;
};

type TError = any;

export function useCollectionStatusQuery<
  Response = CollectionStatusResponse,
  Error = TError,
>(providerId?: DeviceId, queryConfig?: any) {
  const { user } = useUser();

  let url = Endpoints.collectionsStatus;

  if (providerId !== undefined) {
    url += `/${providerId}`;
  }

  return useQuery<Response, Error>(
    [url, { url, token: user?.token }],
    () => fetchStatus(user?.token, url),
    {
      enabled: !!user?.token,
      retry: 0,
      ...(queryConfig || {}),
    },
  );
}

export function useProviderStatusQuery<
  Response = CollectionStatusProvider,
  Error = TError,
>(providerId: DeviceId, queryConfig?: any) {
  const { user } = useUser();

  let url = Endpoints.collectionsStatus + `/${providerId}`;

  return useQuery<Response, Error>(
    [url, { url, token: user?.token }],
    () => fetchStatus(user?.token, url),
    {
      enabled: !!user?.token,
      retry: 0,
      ...(queryConfig || {}),
    },
  );
}

export const getConnectionStatusFromRedirectUrl = (
  url: string,
): ConnectionStatus => {
  if (typeof url !== "string") throw new Error("url must be a string");

  const params = url.split("?")[1];

  const { status } = qs.parse(params);

  // sometimes fitbit returns status=success#_=_ so we need to trim it
  // TO DO: trim #_=_ on the back
  // @ts-ignore
  return status.replace("#_=_", "") as ConnectionStatus;
};

export type ConnectionStatus = "success" | "error";
export type CollectionStatusResponse = CollectionStatusProvider[];
export type CollectionStatusProviderResponse = Omit<
  CollectionStatusProvider,
  "name"
>;

export type CollectionStatusProvider = {
  name: DeviceId;
  connected: boolean;
  days: number;
  has_enough_data: boolean;
  is_receiving_data: boolean;
};
