import React from "react";

import "./privacy-policy.module.scss";

const PrivacyPolicyContent = ({ isScreen }: { isScreen?: boolean }) => (
  <>
    <div className="subheader">
      Qumata Group Ltd
      <br /> Qumata Score App and Website Privacy Policy
    </div>

    {isScreen && <div className="subheader" />}

    <div className="text">Last updated: 2019 08 22</div>
    <div className="text">
      Please read this privacy policy (the Privacy Policy) carefully. It sets
      out important information in relation to how we process your personal data
      when you use the Qumata Score App and the Score website [{" "}
      <a
        href="https://oneclick.qumata.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        oneclick.qumata.com
      </a>{" "}
      ] (the Website).
    </div>
    <div className="text">
      In broad terms, the term “personal data” means any information that
      relates to you and that can be used to identify you, directly or
      indirectly. This information includes your name, email address, phone
      number, location data and information about your health.
    </div>
    <div className="text">
      If you have any requests concerning your personal data or any queries with
      regard to our processing of your personal data, please contact us at{" "}
      <a href="mailto:dpo@qumata.com" target="_top">
        dpo@qumata.com
      </a>
      .
    </div>

    <div className="subheader">Contents</div>

    <a className="header-link" href="#about-us">
      About us
    </a>
    <a className="header-link" href="#purpose-of-this-privacy-policy">
      Purpose of this Privacy Policy
    </a>
    <a
      className="header-link"
      href="#lawful-basis-for-processing-your-personal-data"
    >
      Lawful basis for processing your personal data
    </a>
    <a className="header-link" href="#how-we-use-your-personal-data">
      How we use your personal data
    </a>
    <a className="header-link" href="#what-data-we-may-collect-from-you">
      What data we may collect from you
    </a>
    <a className="header-link" href="#how-we-collect-information-from-you">
      How we collect information from you
    </a>
    <a className="header-link" href="#security-and-international-transfers">
      Security and international transfers
    </a>
    <a className="header-link" href="#transfers-of-your-data">
      Transfers of your data
    </a>
    <a className="header-link" href="#retention-of-your-data">
      Retention of your data
    </a>
    <a
      className="header-link"
      href="#rights-in-relation-to-your-ersonal-information"
    >
      Rights in relation to your personal information
    </a>
    <a className="header-link" href="#cookies">
      Cookies
    </a>
    <a className="header-link" href="#marketing">
      Marketing
    </a>
    <a className="header-link" href="#third-party-links">
      Third party links
    </a>
    <a className="header-link" href="#amendments-to-this-privacy-policy">
      Amendments to this Privacy Policy
    </a>
    <a className="header-link" href="#closing-your-hh-profile">
      Closing your Qumata Profile
    </a>
    <a
      className="header-link"
      href="#questions-in-relation-to-this-privacy-policy"
    >
      Questions in relation to this Privacy Policy
    </a>

    <div className="subheader" id="about-us">
      1. About us
    </div>
    <div className="text">
      We are Qumata Group Ltd, a company incorporated and registered in England
      and Wales with company number 10964467 and our registered office is at 14
      Dufferin street, EC1Y 8PD London, England (Qumata, we, us or our). We
      provide Internet and mobile app-based services.
    </div>

    <div className="subheader" id="purpose-of-this-privacy-policy">
      2. Purpose of this Privacy Policy
    </div>
    <div className="text">
      2.1. This Privacy Policy contains important information about what
      personal data we may collect from you; how we will use, store and protect
      your personal data; with whom we may share your personal data; and your
      rights under relevant data protection laws.
    </div>
    <div className="text">
      2.2. It is important that you read this Privacy Policy together with any
      other privacy notice or fair processing notice we may provide on specific
      occasions when we are collecting or processing personal data about you so
      that you are fully aware of how and why we are using your personal data.
      This Privacy Policy supplements the other notices and is not intended to
      override them.
    </div>

    <div
      className="subheader"
      id="lawful-basis-for-processing-your-personal-data"
    >
      3. Lawful basis for processing your personal data
    </div>
    <div className="text">
      <span>
        Under data protection laws, we must have a legal basis in order to
        process your personal data. The legal bases on which we may process your
        data are set out below.
      </span>
      <ul>
        <li>
          We will process your personal data where you have given us your
          consent to do so for one or more specified reasons. This is
          particularly important in relation to processing information about
          your health where we require your explicit consent.
        </li>
        <li>
          We may also process your personal data where it is necessary for the
          purposes of our legitimate interests in the effective delivery of
          information and services to you and in the effective and lawful
          operation of our business (provided these do not interfere with your
          rights).
        </li>
        <li>
          We may also process your personal data to satisfy any legal and/or
          regulatory obligations to which we are subject.
        </li>
      </ul>
    </div>

    <div className="subheader" id="how-we-use-your-personal-data">
      4. How we use your personal data
    </div>
    <div className="text">
      4.1. The Qumata Score App and Website services cannot be provided without
      an individual providing us with their personal data. We therefore need
      your personal data to provide the services of the Qumata Score App and
      Website.
    </div>
    <div className="text">
      4.2. The aim of the Qumata Score App and Website is provide an aggregate
      wellness score (Wellness Score) to the corresponding client of Qumata from
      which the user is requesting a service. The Wellness Score is an analysis
      of a user’s personal data to calculate his/her risk to be diagnosed for a
      set of medical conditions (also referred to as “prevention opportunities”
      in the user interface). The aggregate Wellness Score is in the form of
      either a synthetic global score or a list of medical conditions with the
      corresponding score. This score is an analysis of the user’s risk against
      that of an average risk person, for both the synthetic global risk and
      risk per medical condition.
    </div>
    <div className="text">
      4.3. For each user (described below), Qumata collates and processes
      his/her personal data with the aim of providing the Qumata Score App and
      Website services to the user and the corresponding client (described
      below).
    </div>
    <div className="text">
      <p>Example</p>
      <span>
        You (referred to as a “user” in this Privacy Policy) request a service
        from a Qumata client (client), e.g. an insurer calculating the price of
        an insurance policy during the underwriting process. In order to fulfil
        the service request, the client recommends that you provide a set of
        personal data directly to Qumata for the purposes of calculating a
        wellness score which is to be shared with the client. The client could
        include companies such as insurers, employers or hospitals.
      </span>
    </div>
    <div className="text">
      4.4. We also use the aggregated anonymised and non-personal data for use
      in research activities, primarily to improve our wellness model. We store
      this anonymised data indefinitely. You will not be identifiable from this
      data.
    </div>
    <div className="text">
      4.5. Collated or calculated personal data (apart from the Wellness Score)
      contained in a user’s profile is never communicated to a client without
      being anonymised, de-identified or aggregated in statistical content, in
      ways that the client cannot personally identify individual user(s).
    </div>
    <div className="text">
      4.6. As a user of the Qumata Score App and Website, you will receive
      notifications either by email, SMS, phone notifications or any other means
      as part of the usage of the Qumata Score App and Website services.
      Notifications are used for non-commercial messages such as notifications
      to a user on the status of his/her data analysis and change(s) in the
      Privacy Policy [{" "}
      <a
        href="https://oneclick.qumata.com/data-privacy-policy"
        target="_blank"
        rel="noopener noreferrer"
      >
        oneclick.qumata.com/data-privacy-policy
      </a>{" "}
      ]. We will inform you about the means through which you will receive
      notifications. As part of the access to your personal data, you will be
      able to change your settings regarding the notification means.
    </div>
    <div className="text">
      4.7 We may process your personal data to provide customer services to you,
      including to respond to your enquiries or to fulfil any of your requests
      for information.
    </div>
    <div className="text">
      4.8 It is important that the personal data we hold about you is accurate
      and current. Please keep us informed if your personal data changes during
      your relationship with us.
    </div>

    <div className="subheader" id="what-data-we-may-collect-from-you">
      5. What data we may collect on you
    </div>
    <div className="text">
      <span>
        Details of the personal data that we may collect on you and process are
        set out below.
      </span>
      <ul>
        <li>
          Information about your health, ethnicity origin. We require this
          information to provide the services of the Qumata Score App and
          Website.
        </li>
        <li>
          The consent that you grant to us to enable us to collect your data
          from different sources.
        </li>
        <li>
          Information extracted from your smart devices including (but not
          limited to) gender, age, weight, pre-existing conditions, walking and
          running distance, cycling distance, heart rate (and related
          information), weight and BMI.
        </li>
        <li>
          Activity information and bio-markers made available from your smart
          devices.
        </li>
        <li>
          Information that you provide to us through your user profile which you
          create.
        </li>
        <li>Email address and telephone numbers.</li>
        <li>Your feedback and survey responses.</li>
        <li>
          Information about how you use the Qumata Score App and Website and our
          other products and services.
        </li>
      </ul>
    </div>

    <div className="subheader" id="how-we-collect-information-from-you">
      6. How we collect information on you
    </div>
    <div className="text">
      <span>We collect your personal data in a number of ways. </span>
      <ul>
        <li>
          <span>
            Directly. This includes information such as your contact details,
            health details and all data which you directly provide to us when
            you fill in online forms or correspond with us in any way, for
            example when you:
          </span>
          <ul>
            <li>create your account on the Qumata Score App and/or Website;</li>
            <li>submit a query;</li>
            <li>
              request or consent to marketing materials being sent to you; or
            </li>
            <li>provide us with feedback.</li>
          </ul>
        </li>
        <li>
          Automatically. As you use the Qumata Score App and Website, we may
          collect certain information relating to your browsing patterns and
          technical data about the equipment you are using to access the Qumata
          Score App and Website.
        </li>
        <li>
          Indirectly via our Client, sending us over a list of information.
        </li>
      </ul>
    </div>

    <div className="subheader" id="security-and-international-transfers">
      7. Security and international transfers
    </div>
    <div className="text">
      7.1. In order to protect your personal data, we have appropriate
      organisational and technical security measures. These measures include us
      having engaged two information specialist partners to safeguard your
      personal data, as further described below.
    </div>
    <div className="text">
      7.2. All your personal data is stored on servers located in Ireland in a
      secured infrastructure setup and monitored on a cloud platform called AWS,
      with a software for privacy and personal data management provided by a
      specialised partner called Pryv (see Pryv.com). Pryv’s software is
      designed to ensure that user collated and calculated data are only
      accessible by the user and a restricted list of algorithms and operators
      of the service. Our partners are very strict in security and data
      management and are used by hospitals, clinics and insurers to handle
      personal data.
    </div>
    <div className="text">
      7.3. All transmissions of personal data are encrypted with state of the
      art solutions provided by our partners.
    </div>
    <div className="text">
      7.4. In case of a detected security flaw, a patch will be provided by the
      partners to secure a state of the art encryption and security
      infrastructure.
    </div>
    <div className="text">
      7.5. In the unlikely event of a data breach, we will take steps to
      mitigate any loss or destruction of data and, if required, will notify you
      and any applicable authority of such a breach.
    </div>

    <div className="subheader" id="transfers-of-your-data">
      8. Transfer of your data
    </div>
    <div className="text">Transfers to members of our group</div>
    <div className="text">
      We may share your data with other members of our Group.
    </div>
    <div className="text">Transfers to third parties</div>
    <div className="text">
      In addition to our data scientists, a Qumata client (as referred to in
      section 4 above) and AWS (as referred to in section 7 above), there may be
      circumstances in which we may also need to share your personal data with
      certain third parties, including third parties located outside of the EEA.
    </div>
    <div className="text">
      <span>
        The third parties to which we may transfer your personal data include:
      </span>
      <ul>
        <li>
          calling agents used by us for the purpose of providing customer
          support to you in relation to the services that we provide; and
        </li>
        <li>
          third party software providers (e.g. e-mail providers) which we use to
          provide our services.
        </li>
      </ul>
      <span>
        The security of your data is important to us and we will, therefore,
        only transfer your data to such third parties if:
      </span>
      <ul>
        <li>
          you have expressly consented to your data being shared with specific
          third parties;
        </li>
        <li>
          the third party needs to access the personal data for the purposes of
          us providing our services to you;
        </li>
        <li>
          the third party has agreed to comply with our instructions, required
          data security standards, policies, and procedures and put adequate
          security measures in place;
        </li>
        <li>
          the transfer complies with any applicable cross border transfer
          restrictions and suitable safeguards have been put in place; and
        </li>
        <li>
          a fully executed written contract that contains suitable obligations
          and protections has been entered into between the parties.
        </li>
      </ul>
      <span>
        As mentioned above, we will only transfer your data where suitable
        safeguards have been put in place. These safeguards are intended to
        ensure a similar degree of protection is afforded to your data wherever
        it may be transferred and include:
      </span>
      <ul>
        <li>
          only transferring your personal data to countries which have been
          deemed to provide an adequate level of protection for personal data by
          the European Commission;
        </li>
        <li>
          where your data will be transferred outside of the EEA, entering into
          specific contractual terms which have been approved by the European
          Commission and which give personal data the same protection as within
          the EEA; or
        </li>
        <li>
          where your data will be transferred to the US, ensuring that the third
          party to which we are transferring your data is part of the Privacy
          Shield.
        </li>
      </ul>
      <span>
        For more information on the safeguards used when we transfer personal
        data to third parties, please contact us at{" "}
        <a href="mailto:dpo@qumata.com" target="_top">
          dpo@qumata.com
        </a>
      </span>
    </div>

    <div className="subheader" id="retention-of-your-data">
      9. Retention of your data
    </div>
    <div className="text">
      9.1. Unless we are required to do so for a longer period by law, we will
      retain your personal data for a maximum of ninety days since the end of
      the processing. Thereafter, your personal data will be securely deleted
      automatically and cannot be restored.
    </div>
    <div className="text">
      9.2. For more details about our retention periods, please contact us at{" "}
      <a href="mailto:dpo@qumata.com" target="_top">
        dpo@qumata.com
      </a>
      .
    </div>
    <div className="text">
      9.3. We retain anonymised data. This may remain stored and used by us with
      no time limits. This data is no longer associable to any user and is
      therefore not personal data.
    </div>

    <div
      className="subheader"
      id="rights-in-relation-to-your-ersonal-information"
    >
      10. Rights in relation to your personal information
    </div>
    <div className="text">
      <span>
        10.1. You have certain rights in relation to the personal data we
        process and hold about you. These include:
      </span>
      <ul>
        <li>
          Right of access: you have the right to request access to personal data
          that we may process about you.
        </li>
        <li>
          Right to rectification: you have the right to require us to correct
          any inaccuracies in your data.
        </li>
        <li>
          Right to erasure: you have the right to require us to delete your
          data, subject to certain legal requirements.
        </li>
        <li>
          Right to restriction of processing: you have the right to require us
          to restrict the way in which we process your personal data.
        </li>
        <li>
          Right to object to processing: you have the right to require us to
          stop processing your personal data should you wish the data to be
          retained but no longer processed.
        </li>
        <li>
          Right to data portability: you have the right to obtain from us easily
          and securely the personal data we hold on you for any purpose you see
          fit.
        </li>
        <li>
          Right to withdraw consent: you have the right at any time to withdraw
          consent allowing us to process your personal data (to the extent
          processing is based on consent and consent is the only permissible
          basis for processing).
        </li>
      </ul>
      <span>
        10.2. If you are not able to complete the above via the Qumata App and
        Website, please write to us
        <a href="mailto:dpo@qumata.com" target="_top">
          dpo@qumata.com
        </a>{" "}
        specifying the right you wish to exercise.
      </span>
      <span>
        10.3. Unless we are permitted to do so by applicable law, we will not
        charge a fee for you to exercise any of the rights listed above.
      </span>
      <span>
        10.4. In case you have given your consent to let the Qumata Score App
        and Website access your personal data from a given third party source
        such as Garmin or Apple Health, you can always revoke your consent from
        those sources directly or ask us to revoke this consent manually by
        sending a request to{" "}
        <a href="mailto:dpo@qumata.com" target="_top">
          dpo@qumata.com
        </a>
      </span>
    </div>

    <div className="subheader" id="cookies">
      11. Cookies
    </div>
    <div className="text">Our Website does not use cookies.</div>

    <div className="subheader" id="marketing">
      12. Marketing
    </div>
    <div className="text">
      It is important to us that we only provide you with tailored offers and
      promotions for services which you may want or need. You will therefore
      only receive such offers from us if you have consented to, and have not at
      any point opted out from, receiving marketing communications from us.
    </div>
    <div className="text">
      Opting out from receiving marketing communications from us is easy and you
      may do so at any time by contacting us at{" "}
      <a href="mailto:dpo@qumata.com" target="_top">
        dpo@qumata.com
      </a>
      . We will process your request to be opted-out of marketing within 30 days
      of receipt.
    </div>
    <div className="text">
      We will ensure that we obtain your consent before we share your personal
      data with any company outside of our Group for marketing purposes.
    </div>
    <div className="text">
      Where you opt out of receiving these marketing communications, we may
      still process your personal data for other required purposes, as specified
      in section 4 above.
    </div>

    <div className="subheader" id="third-party-links">
      13. Third party links
    </div>
    <div className="text">
      The Score App and Website may contain links to and from other
      applications, plug-ins and websites of other networks, advertisers, and
      affiliates. If you follow a link to any of these websites, please note
      that they (and any services that may be accessible through them) have
      their own privacy policies and that we do not accept any responsibility or
      liability for these policies or for any personal data that may be
      collected through these apps, websites or services. Please check these
      policies before you submit any personal data to these websites or use such
      services.
    </div>

    <div className="subheader" id="amendments-to-this-privacy-policy">
      14. Amendments to this Privacy Policy
    </div>
    <div className="text">
      14.1. We reserve the right to vary this Privacy Policy from time to time.
      So you know when we make changes to this Privacy Policy, we will amend the
      revision date at the top of this page. The new modified or amended Privacy
      Policy will apply from that revision date. Therefore, we encourage you to
      review this Privacy Policy periodically to be informed about how we are
      protecting your information.
    </div>
    <div className="text">
      14.2. We will notify you in case we make material changes to this Privacy
      Policy. If you do not agree with the amended Privacy Policy then you have
      the right to stop using the Qumata Score App and Website, and should do so
      immediately.
    </div>

    <div className="subheader" id="closing-your-hh-profile">
      15. Closing your Qumata Profile
    </div>
    <div className="text">
      Details about the services, related disclaimers and how to close your
      Qumata profile are available on the Qumata Score App and Website Terms of
      Use [{" "}
      <a
        href="https://oneclick.qumata.com/legal"
        target="_blank"
        rel="noopener noreferrer"
      >
        oneclick.qumata.com/legal
      </a>{" "}
      ]. And the data will be deleted also after a maximum of 90 days since the
      last processing.
    </div>

    <div
      className="subheader"
      id="questions-in-relation-to-this-privacy-policy"
    >
      16. Questions in relation to this Privacy Policy
    </div>
    <div className="text">
      16.1. You should also be aware that you have the right to raise any
      concerns in relation to how we process your personal data to the
      Information Commissioner's Office in the UK (the ICO).
    </div>
    <div className="text">
      16.2 We have appointed a data protection officer (the DPO) who is
      responsible for dealing with any such concerns, in addition to overseeing
      questions in relation to this Privacy Policy and handling requests in
      relation to the exercise of your legal rights. If you have any concerns,
      questions, or requests, please contact the DPO using the details set out
      below.
    </div>
    <div className="text">
      Email address:{" "}
      <a href="mailto:dpo@qumata.com" target="_top">
        dpo@qumata.com
      </a>
    </div>
    <div className="text">
      Post: Data Protection Officer, Qumata Group Ltd, 14 Dufferin street, EC1Y
      8PD London, England.
    </div>
    <div className="text">
      16.3 We will respond to requests and questions addressed to{" "}
      <a href="mailto:dpo@qumata.com" target="_top">
        dpo@qumata.com
      </a>{" "}
      within 15 to 30 calendar days.
    </div>
  </>
);

export default PrivacyPolicyContent;
