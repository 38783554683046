import React from "react";

import PrivacyPolicyContent from "./PrivacyPolicyContent";
import "./styles.scss";

const PrivacyPolicy: React.FunctionComponent<{}> = () => (
  <div className="about">
    <div className="content-container">
      <div className="header">Data Privacy Policy</div>
      <PrivacyPolicyContent isScreen={true} />
    </div>
  </div>
);

export default PrivacyPolicy;
