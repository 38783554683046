import React from "react";

import ConnectDeviceScreen from "screens/ConnectDevice";
import { DeviceId } from "modules/constants";

const GoogleFit: React.FunctionComponent<{}> = () => (
  <ConnectDeviceScreen deviceId={DeviceId.google} />
);

export default GoogleFit;
