import React from "react";
import DeviceHeader from "components/DeviceHeader/DeviceHeader";

import Button from "components/Button";

import classes from "./StartConnectDevice.module.scss";
import { mapIdToDevice } from "screens/ConnectDevice";
import { DeviceId } from "modules/constants";

import "screens/styles.scss";

type StartConnectDeviceProps = {
  device: DeviceId;
  onConnect: () => void;
  isLoading: boolean;
};

const StartConnectDevice: React.FunctionComponent<StartConnectDeviceProps> = ({
  device,
  onConnect,
  isLoading
}) => {
  const { title } = mapIdToDevice[device];
  return (
    <div className={classes["wrapper"]}>
      <div className={classes["content-container"]}>
        <DeviceHeader device={device} />

        <div className={classes["container"]}>
          <div className={classes["content"]}>
            1. Sync your {title} Device to upload your latest activity data to
            your {title} account.
          </div>
          <div className={classes["content"]}>
            2. Sign in to your {title} account to give OneClick access to your{" "}
            {title} data for this process.
          </div>
        </div>
      </div>

      <div className={classes["buttons-container"]}>
        <Button
          type="rounded"
          icon="arrowRight"
          onClick={onConnect}
          pending={isLoading}
          disabled={isLoading}
        >
          Sign In
        </Button>
      </div>
    </div>
  );
};

export default StartConnectDevice;
