import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, FormikValues } from "formik";
import classNames from "classnames";
import Select from "react-select";
import Bugsnag from "@bugsnag/js";
import moment from "moment";
import { Measure, useCollectionStatusQuery } from "modules/utils/hooks";
import HeightPicker from "./HeightPicker";
import WeightPicker from "./WeightPicker";
import Pending from "components/Pending";
import Button from "components/Button";
import FormField from "./Field";
import { useProfileMutation, useProfileQuery } from "modules/utils/hooks";
import { profileSchema, months, years, range } from "./helper";
import Layout from "components/Layout/Layout";
import { RoutesPaths } from "modules/routes";
import {
  useAlert,
  DefaultError,
  DefaultErrorTitle,
} from "modules/context/AlertProvider";
import "./styles.scss";
import { useUser } from "modules/context/UserProvider";
import changeRedirectURL from "modules/utils/redirect";
import apiCall, { Endpoints } from "modules/api";

const monthOptions = months.map((month, index) => ({
  value: `${index + 1}`,
  label: month,
}));

const yearOptions = years.map((year) => ({
  value: year,
  label: year,
}));

const genderOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
];

const smokingOptions = [
  { label: "Never", value: "never" },
  {
    label: "Former (You stopped smoking more than 12 month ago)",
    value: "former",
  },
  { label: "Yes", value: "yes" },
];

export type InputField =
  | "day"
  | "month"
  | "year"
  | "gender"
  | "height"
  | "smoking"
  | "weight"
  | "height_unit"
  | "weight_unit";

const Profile: React.FunctionComponent<{}> = () => {
  const { user } = useUser();
  const { data: profileData, isLoading: isProfileLoading } = useProfileQuery();
  const { mutate, isLoading: isProfileMutateLoading } = useProfileMutation();
  const { data: providersStatus } = useCollectionStatusQuery();
  const navigate = useNavigate();
  const { show } = useAlert();

  const hasEnoughData =
    providersStatus &&
    Object.values(providersStatus).some((status) => status.has_enough_data);

  const [isSubmitted, setSubmitted] = useState(false);
  const [initialValues, setInitialValues] = useState<FormikValues>({});

  const submit = async (submitData: FormikValues): Promise<void> => {
    const {
      day,
      month,
      year,
      gender,
      height,
      smoking,
      weight,
      height_unit,
      weight_unit,
    } = submitData;

    const dob = moment(`${year}-${month}-${day}`, "YYYY-M-D").format(
      "DD/MM/YYYY",
    );

    const profile = {
      dob,
      gender,
      height,
      weight,
      smoking,
      height_unit,
      weight_unit,
    };

    try {
      mutate(profile);

      if (user.redirect) {
        const changedRedirectURL = changeRedirectURL(
          user.redirect,
          hasEnoughData,
        );

        try {
          if (typeof user.token !== "string") throw new Error("token");

          const headers = {
            "Content-Type": "application/json",
            Authorization: user.token,
          };

          await apiCall.post(
            hasEnoughData ? Endpoints.trackThankYou : Endpoints.trackWellDone,
            {},
            { headers },
          );
        } catch (error) {
          console.log(error);
          Bugsnag.notify(error);
        }

        window.location.replace(changedRedirectURL);
      } else {
        navigate(RoutesPaths.conditions);
      }
    } catch (error) {
      Bugsnag.notify(error);
      show(DefaultErrorTitle, <DefaultError />);
    }
  };

  const formStyle = classNames("profile-data-form", {
    "form-submitted": isSubmitted,
  });

  useEffect(() => {
    if (!isProfileLoading && profileData) {
      const { dob, gender, height, weight, smoking, height_unit, weight_unit } =
        profileData;

      const birthday = dob ? dob.split("/") : [];

      setInitialValues({
        gender,
        height,
        weight,
        smoking,
        day: birthday[0],
        month: birthday[1],
        year: birthday[2],
        weight_unit: weight_unit || Measure.kg,
        height_unit: height_unit || Measure.Centimeters,
      });
    }
  }, [isProfileLoading, profileData]);

  return isProfileLoading || isProfileMutateLoading ? (
    <Pending />
  ) : (
    <Layout progressBarPercent={60}>
      <div className="profile">
        <div className="profile-header">Profile Data</div>
        <Formik
          validationSchema={profileSchema}
          onSubmit={submit}
          initialValues={initialValues}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => {
            const onFieldChange = (
              field: InputField,
              value: string | number,
            ) => {
              if (isSubmitted) {
                setSubmitted(false);
              }

              setFieldTouched(field);
              setFieldValue(field, value);
            };

            // !TODO find better solution:
            const daysByMonth =
              moment(
                `${values.year}-${values.month}`,
                "YYYY-MM",
              ).daysInMonth() + 1 || 32;
            const days = Array.from(range(1, daysByMonth, 1));
            const dayOptions = days.map((day) => ({ value: day, label: day }));

            const dayValue = dayOptions.find(
              ({ value }) => parseFloat(value) === parseInt(values.day),
            );

            const monthValue = monthOptions.find(
              ({ value }) => value === parseInt(values.month).toString(),
            );

            const yearValue = yearOptions.find(
              ({ value }) => value.toString() === values.year,
            );

            const genderValue = genderOptions.find(
              ({ value }) => value === values.gender,
            );

            const smokingValue = smokingOptions.find(
              ({ value }) => value === values.smoking,
            );

            const getDaysByMonth = (month, year) => {
              const daysByMonth = moment(
                `${year || values.year || 2003}-${
                  month || values.month || "1"
                }`,
                "YYYY-MM",
              ).daysInMonth();

              return daysByMonth;
            };

            const checkCorrectDay = (field: string, value: string | number) => {
              if (!dayValue) return;

              if (field === "month") {
                if (dayValue.value > getDaysByMonth(value, null)) {
                  onFieldChange("day", getDaysByMonth(value, null));
                }
              }

              if (field === "year") {
                if (dayValue.value > getDaysByMonth(null, value)) {
                  onFieldChange("day", getDaysByMonth(null, value));
                }
              }
            };

            return (
              <>
                <div className={formStyle}>
                  <FormField
                    title="Date of birth*"
                    error={
                      isSubmitted && (errors.day || errors.month || errors.year)
                    }
                    className="birthdate-selector sm-margin-vertical"
                  >
                    <div className="select-container">
                      <Select
                        options={dayOptions}
                        isSearchable={false}
                        defaultValue={dayValue}
                        value={dayValue}
                        className="day-select dropdown-container"
                        classNamePrefix="dropdown"
                        placeholder="Day"
                        onChange={(e) => onFieldChange("day", e.value)}
                      />
                      <Select
                        options={monthOptions}
                        isSearchable={false}
                        defaultValue={monthValue}
                        value={monthValue}
                        className="month-select dropdown-container"
                        classNamePrefix="dropdown"
                        placeholder="Month"
                        onChange={(e) => {
                          onFieldChange("month", e.value);
                          checkCorrectDay("month", e.value);
                        }}
                      />
                      <Select
                        options={yearOptions}
                        isSearchable={false}
                        defaultValue={yearValue}
                        value={yearValue}
                        className="year-select dropdown-container"
                        classNamePrefix="dropdown"
                        placeholder="Year"
                        onChange={(e) => {
                          onFieldChange("year", e.value);
                          checkCorrectDay("year", e.value);
                        }}
                      />
                    </div>
                  </FormField>

                  <FormField
                    title="Gender at Birth*"
                    error={isSubmitted && errors.gender}
                    className="gender-at-birth"
                  >
                    <Select
                      placeholder="Select"
                      classNamePrefix="dropdown"
                      defaultValue={genderValue}
                      value={genderValue}
                      isSearchable={false}
                      options={genderOptions}
                      onChange={(e) => onFieldChange("gender", e.value)}
                    />
                  </FormField>

                  <FormField
                    title="Smoking*"
                    error={isSubmitted && errors.smoking}
                    className="flex-1"
                  >
                    <Select
                      placeholder="Select"
                      classNamePrefix="dropdown"
                      isSearchable={false}
                      defaultValue={smokingValue}
                      value={smokingValue}
                      options={smokingOptions}
                      onChange={(e) => onFieldChange("smoking", e.value)}
                    />
                  </FormField>

                  <HeightPicker
                    height={values.height}
                    measure={values.height_unit}
                    error={isSubmitted && errors.height}
                    onFieldChange={onFieldChange}
                  />

                  <WeightPicker
                    weight={values.weight}
                    measure={values.weight_unit}
                    error={isSubmitted && errors.weight}
                    onFieldChange={onFieldChange}
                  />
                </div>

                <div className="form-action-container">
                  <Button
                    icon="arrowRight"
                    onClick={() => {
                      handleSubmit();
                      setSubmitted(true);
                    }}
                    type="rounded"
                    isSubmit={true}
                  >
                    Continue
                  </Button>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
};

export default Profile;
