import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

export const RoutesPaths = {
  home: "/",
  about: "/about",
  appleHealthTutorial: "/apple-health-tutorial",
  appleHealthTutorial13: "/apple-health-tutorial-13",

  awaitAppleHealthConnection: "/await-apple-health",
  chooseDevices: "/choose-devices",
  reselectDevices: "/reselect-devices",
  conditions: "/conditions",
  connectAppleHealth: "/connect-apple-health",
  connectAppleWatch: "/connect-apple-watch",

  connectFitbit: "/connect-fitbit",
  uploadingFitbit: "/uploading-fitbit",
  connectGarmin: "/connect-garmin",
  uploadingGarmin: "/uploading-garmin",
  connectGoogleFit: "/connect-google-fit",
  uploadingGoogleFit: "/uploading-google-fit",
  connectUnderarmour: "/connect-underarmour",
  uploadingUnderarmour: "/uploading-underarmour",

  dataPrivacyPolicy: "/data-privacy-policy",
  installGoogleFit: "/install-google-fit",
  legal: "/legal",
  profile: "/profile",
  selectDevices: "/select-devices",
  setUpGoogleFit: "/set-up-google-fit",
  signIn: "/sign-in",
  thankYou: "/thank-you",
  wellDone: "/well-done",
  oneclickPrivacy: "/oneclick-privacy",
  oneclickTerms: "/oneclick-terms"
};
