import React from "react";
import { useNavigate } from "react-router";
import Button, { ButtonType } from "components/Button";
import { AccessStatus, DeviceId } from "modules/constants";

import DeviceHeader from "components/DeviceHeader/DeviceHeader";

import classes from "./CollectingDevice.module.scss";
import { mapIdToDevice } from "screens/ConnectDevice";
import { RoutesPaths } from "modules/routes";
import { useUser } from "modules/context/UserProvider";
import { useCollectionStatusQuery } from "modules/utils";
import apiCall, { Endpoints } from "modules/api";
import Bugsnag from "@bugsnag/js";
import changeRedirectURL from "modules/utils/redirect";

const CollectingDevice = ({ device }: { device: DeviceId }) => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { data: providersStatus } = useCollectionStatusQuery();

  const hasEnoughData =
    providersStatus &&
    Object.values(providersStatus).some((status) => status.has_enough_data);

  const handleNext = async () => {
    if (user.redirect) {
      const changedRedirectURL = changeRedirectURL(
        user.redirect,
        hasEnoughData,
      );

      try {
        if (typeof user.token !== "string") throw new Error("token");

        const headers = {
          "Content-Type": "application/json",
          Authorization: user.token,
        };

        await apiCall.post(
          hasEnoughData ? Endpoints.trackThankYou : Endpoints.trackWellDone,
          {},
          { headers },
        );
      } catch (error) {
        console.log(error);
        Bugsnag.notify(error);
      }

      window.location.replace(changedRedirectURL);
    } else {
      navigate(RoutesPaths.profile);
    }
  };

  return (
    <>
      <DeviceHeader device={device} status={AccessStatus.collecting} />

      <div className={classes["collecting-container"]}>
        <div className={classes["collecting-content"]}>
          Your {mapIdToDevice[device].title} app is connected successfully but
          it will still take some time to upload all your activity data.
        </div>
        <div className={classes["collecting-content"]}>
          In the meantime, you can continue to the next step of the process and
          complete your journey.
        </div>
        <div className={classes["collecting-content"]}>
          If you'd like to connect another data source, select 'Try another
          device' at the bottom of this page.
        </div>
      </div>
      <div className={classes["buttons-container"]}>
        <Button
          icon="arrowRight"
          type={ButtonType.rounded}
          onClick={handleNext}
        >
          Next
        </Button>

        <Button
          type={ButtonType.underlined}
          onClick={() => navigate(RoutesPaths.chooseDevices)}
        >
          Try another device
        </Button>
      </div>
    </>
  );
};

export default CollectingDevice;
