import { images } from "assets/images";

import { DeviceId } from "./constants";
import { RoutesPaths } from "./routes";

export const devices = [
  {
    name: DeviceId.apple,
    title: "Apple Health",
    subtitle: "(Apple Phone or Watch)",
    connectionRoute: RoutesPaths.connectAppleHealth,
    icon: images.appleHealthLogo
  },
  {
    name: DeviceId.google,
    title: "Google Fit",
    subtitle: "",
    connectionRoute: RoutesPaths.connectGoogleFit,
    icon: images.googleFitLogo
  },
  {
    name: DeviceId.fitbit,
    title: "Fitbit",
    subtitle: "",
    connectionRoute: RoutesPaths.connectFitbit,
    icon: images.fitbitLogo
  },
  {
    name: DeviceId.garmin,
    title: "Garmin",
    subtitle: "",
    connectionRoute: RoutesPaths.connectGarmin,
    icon: images.garminLogo
  },
  {
    name: DeviceId.underarmour,
    title: "Map My Run",
    subtitle: "(Under Armour)",
    connectionRoute: RoutesPaths.connectUnderarmour,
    icon: images.underarmourLogo
  }
];
