import React from "react";

import "./styles.scss";

const AboutPage: React.FunctionComponent<{}> = () => (
  <div className="about">
    <div className="content-container">
      <div className="header">About Qumata</div>
      <div className="subheader">The core of our technology</div>
      <div className="text">
        Qumata is developing one of the most accurate digital data health risk
        assessments on the market. Once a user gives access to their personal
        data from their smartphone or wearables, it only takes a few minutes for
        their unique health risk to be calculated. This health risk includes a
        list for potential diagnosis of over 800 medical conditions according to
        international ICD10 codes. With the ongoing rapid development and
        distribution of more advanced smartphones and fitness trackers, Qumata
        is leading the associated device-based health tracking revolution.
      </div>
      <div className="subheader">We introduce insurers to the REAL you</div>
      <div className="text">
        The Qumata OneClick application revolutionises the way insurers
        underwrite. You are defined by more than just your age, existing medical
        conditions or the medical history of your family. From fitness tracking
        using wearables, to a daily step count from your mobile phone, we ensure
        that the data used to price your policy reflects who you are and how you
        live. Qumata offers a swift and efficient process, getting down to the
        core of your health condition and leveraging our precise health risk
        assessment for underwriting.
      </div>
      <div className="text">
        {" "}
        To find out more about our products, visit{" "}
        <a
          href="http://www.qumata.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          www.qumata.com
        </a>
      </div>
    </div>
  </div>
);

export default AboutPage;
