import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Button from "components/Button";
import { images } from "assets/images";
import { RoutesPaths } from "modules/routes";
import "./styles.scss";

interface Step {
  illustration: any;
  title: string;
  explanation?: any;
}

const steps: Step[] = [
  {
    illustration: images.appleHealthTutorial1,
    title: "Step 1",
    explanation: (
      <div className="description">
        Open the Apple Health app on your mobile phone.
      </div>
    )
  },
  {
    illustration: images.appleHealthTutorial2_13,
    title: "Step 2",
    explanation: (
      <div className="description">
        Choose “Profile” at Summary page.
        <div>
          <Link to={RoutesPaths.appleHealthTutorial}>
            I don’t see this page?
          </Link>
        </div>
      </div>
    )
  },
  {
    illustration: images.appleHealthTutorial3_13,
    title: "Step 3",
    explanation: <div className="description">Choose “Apps” from the list.</div>
  },
  {
    illustration: images.appleHealthTutorial3,
    title: "Step 4",
    explanation: (
      <div className="description" style={{ marginLeft: "12px" }}>
        Select the OneClick-Connect app.
      </div>
    )
  },
  {
    illustration: images.appleHealthTutorial4,
    title: "Step 5",
    explanation: (
      <div className="description" style={{ marginLeft: "12px" }}>
        Select “Turn All Categories On”.
        <p className="additional-explanation">
          If you <span>want to disconnect</span> than turn all categories off
        </p>
      </div>
    )
  }
];

const AppleHealthTutorial13: React.FC<{}> = () => {
  const navigate = useNavigate();
  const handleConsent = () => navigate(RoutesPaths.chooseDevices);

  return (
    <div className="apple-health-tutorial">
      <div className="header">Apple Health Consent Tutorial</div>
      <div className="subheader">How to change your consent</div>
      <div className="steps">
        {steps.map((step, index) => (
          <div className="step" key={step.title}>
            <div
              className="title"
              style={{ marginLeft: index === 3 || index === 4 ? "12px" : "0" }}
            >
              {step.title}
            </div>
            <img
              className="illustration"
              src={step.illustration}
              alt={step.title}
            />
            {step.explanation}
          </div>
        ))}
      </div>
      <Button
        overrideButtonClass="button"
        overrideButtonStyle={{ marginBottom: 16 }}
        onClick={handleConsent}
        type="rounded"
      >
        I Gave Consent
      </Button>
    </div>
  );
};

export default AppleHealthTutorial13;
