import React from "react";

import ConnectDeviceScreen from "screens/ConnectDevice";
import { DeviceId } from "modules/constants";

const ConnectGarminScreen: React.FunctionComponent<{}> = () => (
  <ConnectDeviceScreen deviceId={DeviceId.garmin} />
);

export default ConnectGarminScreen;
