import React from "react";

import { handlePreventDefault } from "modules/utils";

import "./styles.scss";

interface Props {
  onClose?: () => void;
}

const AboutDataCollection: React.FunctionComponent<Props> = ({ onClose }) => (
  <div className="about-data-collection" onClick={onClose}>
    <div
      className="about-data-collection-container"
      onClick={handlePreventDefault}
    >
      <div className="about-data-collection-content">
        <div className="controls">
          <button className="button close" onClick={onClose}>
            Close
          </button>
        </div>
        <div className="title">
          <span>About data collection</span>
        </div>
        <p className="description">
          <p>
            Your smartphone, smartwatch and wearables will collect your activity
            data such as steps, intense activity, floor climbs and heart rate.
          </p>
          <p>
            Because everyone is unique, Qumata analyses your activity data
            continuously to establish your personal health context.
          </p>
          <p>
            We need your consent to access your data so we can carry out this
            process successfully.
          </p>
        </p>
      </div>
    </div>
  </div>
);

export default AboutDataCollection;
