import React from "react";
import "./styles.css";
import { useUser } from "modules/context/UserProvider";
import DeviceItemStatus from "./DeviceItemStatus";
import { DeviceId } from "modules/constants";
import { images } from "assets/images";

export interface DeviceDataInterface {
  connected: boolean;
  days: number;
  has_enough_data: boolean;
  is_receiving_data: boolean;
  name?: DeviceId;
}

export type DeviceItemType = {
  name?: String;
  title: String;
  subtitle: String;
  connectionRoute: String;
  icon: string;
};

export enum ProviderStatus {
  connected = "connected",
  notConnected = "notConnected",
  noData = "noData",
}

interface DeviceItemPropsInterface {
  status?: DeviceDataInterface;
  device: DeviceItemType;
  isLoading: boolean;
  handleNext: (route: String) => void;
  openRevokeModal: (name: String) => void;
}

const DeviceItem: React.FunctionComponent<DeviceItemPropsInterface> = ({
  device,
  status,
  isLoading,
  handleNext,
  openRevokeModal,
}) => {
  const { user } = useUser();

  const handleClick = () => {
    if (isLoading) {
      return;
    }

    if (status?.connected) {
      openRevokeModal(device.name);
    } else {
      handleNext(device.connectionRoute);
    }
  };

  const hasStatus =
    status?.connected ||
    (user?.id && localStorage.getItem(device.name + "_tried" + user.id));

  return (
    <div className="provider-wrapper" onClick={handleClick}>
      <div className="provider-left">
        <div className="logo-container">
          <img alt="logo" className="service-icon" src={device.icon} />
        </div>
        <div className="device-item">
          <div className="device-name">
            <span className="device-title">{device.title}</span>{" "}
            {device.subtitle}
          </div>

          <div className="status-container">
            {hasStatus && !isLoading ? (
              <DeviceItemStatus status={status} />
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>

      <div className="provider-right">
        {isLoading ? (
          <img src={images.spinner} alt="loading" className={"loading-image"} />
        ) : (
          <div
            className={`switch-container ${
              status?.connected ? "connected" : ""
            }`}
          >
            <div
              className={`switch ${status?.connected ? "connected" : ""}`}
            ></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DeviceItem;
