import React from "react";
import { RoutesPaths } from "modules/routes";
import { useNavigate } from "react-router-dom";

import ConnectedDeviceHeader from "../DeviceHeader/DeviceHeader";
import { AccessStatus, DeviceId } from "modules/constants";
import Button, { ButtonType } from "components/Button";
import classes from "./NotConnectedDevice.module.scss";
import { mapIdToDevice } from "screens/ConnectDevice";

const NotConnectedDevice = ({
  device,
  handleConnect
}: {
  device: DeviceId;
  handleConnect: () => void;
}) => {
  const navigate = useNavigate();

  return (
    <div className={classes["wrapper"]}>
      <div className={classes["content-container"]}>
        <ConnectedDeviceHeader
          device={device}
          status={AccessStatus.notReceived}
        />

        <div className={classes["container"]}>
          <div className={classes["content"]}>
            We did not receive your consent so we cannot access your{" "}
            {mapIdToDevice[device].title} data.
          </div>
          <div className={classes["content"]}>
            Please give your consent again.
          </div>
          <div className={classes["content"]}>
            You can also connect another data source instead.
          </div>
          <div className={classes["content"]}>
            We need at least one data source to create your personalised
            assessment.
          </div>
        </div>
      </div>
      <div className={classes["buttons-container"]}>
        <Button
          type={ButtonType.rounded}
          onClick={handleConnect}
          icon="arrowRight"
        >
          Give consent
        </Button>
        <Button
          type={ButtonType.underlined}
          onClick={() => navigate(RoutesPaths.chooseDevices)}
        >
          Try another device
        </Button>
      </div>
    </div>
  );
};

export default NotConnectedDevice;
