import React, { useEffect, useState } from "react";

import { useUser } from "modules/context/UserProvider";
import { useProfileQuery } from "modules/utils/hooks";
import ContactUs from "components/Modals/ContactUs";
import apiCall, { Endpoints } from "modules/api";
import Layout from "components/Layout/Layout";
import { config } from "modules/constants";
import Header from "./Header";
import Button from "components/Button";

import "./styles.scss";
import Bugsnag from "@bugsnag/js";

interface Props {
  history: any;
}

const ThankYou: React.FunctionComponent<Props> = ({ history }) => {
  const { data: profile } = useProfileQuery();
  const { user } = useUser();

  const [showContactUs, toggleContactUs] = useState(false);
  const [tracked, setTracked] = useState(false);

  useEffect(() => {
    if (tracked || profile?.thank_you_screen_visited_at) return;
    if (!user?.token || !profile) return;

    const trackScreen = async () => {
      try {
        if (typeof user.token !== "string") throw new Error("token");

        const headers = {
          "Content-Type": "application/json",
          Authorization: user.token,
        };

        await apiCall.post(Endpoints.trackThankYou, {}, { headers });

        setTracked(true);
      } catch (error) {
        console.log(error);
        Bugsnag.notify(error);
      }
    };

    trackScreen();
  }, [profile, tracked, profile?.thank_you_screen_visited_at, user?.token]);

  return (
    <React.Fragment>
      <div className="thank-you-container">
        <div className="header-wrapper">
          <Header onContactUs={() => toggleContactUs(true)} />
        </div>
        <Layout isProgressBar={false}>
          <div className="form-wrapper">
            <div className="content-container">
              <div className="header">Thank you!</div>
              <div className="content">
                Congratulations! You successfully finished the process and your
                policy is now ready.
              </div>
              <div className="content">
                Please open the below link to view and confirm your insurance
                policy.
              </div>
              <div className="content">
                <a href=" " className="client-link">
                  www.clientwebsite/finalprice.com
                </a>
              </div>
              <a href={config.feedbackUrl} className="link">
                <Button
                  type="rounded"
                  overrideButtonStyle={{
                    border: "1px solid",
                    justifyContent: "center",
                    textDecoration: "none",
                  }}
                >
                  Share Feedback
                </Button>
              </a>
              <div className="small-content">
                Please complete a short form to help us improve our product.
              </div>
            </div>
          </div>
        </Layout>
      </div>
      {showContactUs && <ContactUs onClose={() => toggleContactUs(false)} />}
    </React.Fragment>
  );
};

export default ThankYou;
