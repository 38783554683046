import React from "react";

import DeviceDataUploading from "screens/DeviceDataUploading";
import { DeviceId } from "modules/constants";

const GoogleFitUploading: React.FunctionComponent<{}> = () => (
  <DeviceDataUploading deviceId={DeviceId.google} />
);

export default GoogleFitUploading;
