import React from "react";
import { useLocation, useNavigate } from "react-router";
import { useMedia } from "react-use-media";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { useAlert } from "modules/context/AlertProvider";

import { images } from "assets/images";
import Button from "components/Button";
import "./styles.scss";

const getLinkStyle = (pathname: string, targetPathname: string) => {
  return {
    fontFamily:
      pathname === targetPathname
        ? "Modern-Era-Mono-Bold"
        : "Modern-Era-Mono-Regular",
  };
};

const HOME = "/";
const ABOUT = "/about";
const LEGAL = "/legal";
const PRIVACY = "/data-privacy-policy";

const HOME_SCREEN_ROUTES = [HOME, ABOUT, LEGAL, PRIVACY];

const CloseButton = () => {
  const { show } = useAlert();
  const isWide = useMedia({ minWidth: 768 });
  const navigate = useNavigate();

  const onShowClosePopup = () => {
    navigate("/");
    show(
      "",
      <div>
        <h3 className="thank-you-title">Thank You</h3>
        <p>
          Your data is saved. You can open this web app again by using the
          invitation link.
        </p>
      </div>,
    );
  };

  return isWide ? (
    <Button
      type="text"
      overrideTextClass="close-btn"
      onClick={onShowClosePopup}
    >
      Close
    </Button>
  ) : (
    <div>
      <img
        alt="close icon"
        src={images.close}
        className="image"
        onClick={onShowClosePopup}
      />
    </div>
  );
};

const Header = () => {
  const { showContactUs } = useAlert();
  const location = useLocation();
  const { pathname } = location;

  const useWhite = [ABOUT, LEGAL, PRIVACY].includes(pathname);

  const isWide = useMedia({ minWidth: 768 });
  const buttonIcon = useWhite ? images.helpWhite : images.contactUs;
  const textColor = { whiteText: useWhite };
  const bgColor = {
    blueBackground: useWhite,
  };
  const headerStyle = classNames("landing-header", bgColor);

  const linkStyle = classNames("link", textColor);
  const headerStyleText = classNames("header-text", textColor);
  const headerButtonStyle = classNames("header-button right", textColor);
  const headerContainerStyle = classNames("block", bgColor);

  const isHomeRoute = HOME_SCREEN_ROUTES.includes(pathname);

  return (
    <React.Fragment>
      {!isWide && isHomeRoute && (
        <div className={headerContainerStyle}>
          <div className={headerStyleText}>
            <img
              alt="Header logo"
              src={images.oneclick}
              className="header-logo"
            />
          </div>
        </div>
      )}

      <div className={headerStyle}>
        <div className="left-content">
          {isHomeRoute ? (
            <>
              <Link
                to={HOME}
                className={linkStyle}
                style={getLinkStyle(pathname, HOME)}
              >
                Home
              </Link>

              <Link
                to={ABOUT}
                className={linkStyle}
                style={getLinkStyle(pathname, ABOUT)}
              >
                About
              </Link>

              <Link
                to={LEGAL}
                className={linkStyle}
                style={getLinkStyle(pathname, LEGAL)}
              >
                Legal
              </Link>

              <Link
                to={PRIVACY}
                className={linkStyle}
                style={getLinkStyle(pathname, PRIVACY)}
              >
                Privacy
              </Link>
            </>
          ) : (
            <CloseButton />
          )}
        </div>

        {(isWide || (!isWide && !isHomeRoute)) && (
          <div className="header-logo-container">
            <img
              src={images.oneclick}
              alt="Header logo"
              className="header-logo"
            />
          </div>
        )}

        <div className="right-content">
          <div>
            <div className={headerButtonStyle} onClick={showContactUs}>
              <span>Contact Us</span>
              <img alt="contact us" src={buttonIcon} className="image" />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
