import React from "react";
import classNames from "classnames";

import "./styles.scss";

interface ProgressBarProps {
  progress: number;
}

const ProgressBar: React.FunctionComponent<ProgressBarProps> = ({
  progress
}) => {
  const progressBarContainer = classNames("progress-bar-container");

  return (
    <div className={progressBarContainer}>
      <div className="progress-bar-background">
        <div className="filled-area" style={{ width: `${progress}%` }} />
      </div>
    </div>
  );
};

export default ProgressBar;
