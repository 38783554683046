import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { history } from "modules/routes";

import { RoutesPaths } from "modules/routes";
import Header from "components/Header";

import SignIn from "screens/SignIn";
import ChooseDevices from "screens/ChooseDevices";
import AppleHealth from "screens/AppleHealth";
import GoogleFit from "screens/GoogleFit";
import Fitbit from "screens/Fitbit";
import Garmin from "screens/Garmin";
import Underarmour from "screens/Underarmour";

import GoogleFitUploading from "screens/GoogleFitUploading";
import FitbitUploading from "screens/FitbitUploading";
import GarminUploading from "screens/GarminUploading";
import UnderarmourUploading from "screens/UnderarmourUploading";

import Home from "screens/Home/Home";
import WellDone from "screens/WellDone";
import ThankYou from "screens/ThankYou";
import About from "screens/About/About";
import Legal from "screens/Legal/Legal";
import NotFound from "screens/NotFound";
import Profile from "screens/Profile/profile";
import Conditions from "screens/Profile/conditions";
import PrivacyPolicy from "screens/PrivacyPolicy/PrivacyPolicy";
import AppleHealthTutorial from "screens/AppleHealthTutorial/AppleHealthTutorial";
import AppleHealthTutorial13 from "screens/AppleHealthTutorial13/AppleHealthTutorial13";

const AppNavigator: React.FC<{}> = () => (
  <BrowserRouter>
    <Header />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path={RoutesPaths.legal} element={<Legal />} />
      <Route path={RoutesPaths.oneclickTerms} element={<Legal />} />
      <Route path={RoutesPaths.about} element={<About />} />
      <Route path={RoutesPaths.oneclickPrivacy} element={<PrivacyPolicy />} />
      <Route path={RoutesPaths.dataPrivacyPolicy} element={<PrivacyPolicy />} />

      {/* Sign in screen with policies */}
      <Route path={RoutesPaths.signIn} element={<SignIn />} />

      {/* Choose providers screen */}
      <Route path={RoutesPaths.chooseDevices} element={<ChooseDevices />} />

      {/* Providers screens */}
      <Route path={RoutesPaths.connectAppleHealth} element={<AppleHealth />} />
      <Route path={RoutesPaths.connectGoogleFit} element={<GoogleFit />} />
      <Route path={RoutesPaths.connectFitbit} element={<Fitbit />} />
      <Route path={RoutesPaths.connectGarmin} element={<Garmin />} />
      <Route path={RoutesPaths.connectUnderarmour} element={<Underarmour />} />

      <Route
        path={RoutesPaths.uploadingGoogleFit}
        element={<GoogleFitUploading />}
      />
      <Route path={RoutesPaths.uploadingFitbit} element={<FitbitUploading />} />
      <Route path={RoutesPaths.uploadingGarmin} element={<GarminUploading />} />
      <Route
        path={RoutesPaths.uploadingUnderarmour}
        element={<UnderarmourUploading />}
      />
      {/* Apple disconnect screens */}
      <Route
        path={RoutesPaths.appleHealthTutorial}
        element={<AppleHealthTutorial />}
      />
      <Route
        path={RoutesPaths.appleHealthTutorial13}
        element={<AppleHealthTutorial13 />}
      />

      {/* Profile screen */}
      <Route path={RoutesPaths.profile} element={<Profile />} />

      {/* Conditions screen */}
      <Route path={RoutesPaths.conditions} element={<Conditions />} />

      {/* Result screens */}
      <Route
        path={RoutesPaths.wellDone}
        element={<WellDone history={history} />}
      />
      <Route
        path={RoutesPaths.thankYou}
        element={<ThankYou history={history} />}
      />

      {/* Not found screen */}
      <Route path="*" element={<NotFound history={history} />} />
    </Routes>
  </BrowserRouter>
);

export default AppNavigator;
