import React from "react";

import { DeviceId } from "modules/constants";
import DeviceDataUploading from "screens/DeviceDataUploading";

const FitbitUploading: React.FunctionComponent<{}> = () => (
  <DeviceDataUploading deviceId={DeviceId.fitbit} />
);

export default FitbitUploading;
