import React from "react";
import { images } from "assets/images";

import "./styles.scss";

const Pending = () => (
  <div className="centerContainer">
    <img
      alt="activity indicator"
      className="pending-icon"
      src={images.pending}
    />
  </div>
);

export default Pending;
