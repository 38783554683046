import React from "react";
import classNames from "classnames";

import "./styles.scss";

interface CheckBoxProps {
  checked?: boolean;
  disabled?: boolean;
  error?: boolean;
  label: string | any;
  onChange?: (event: any) => void;
  value?: string;
}

const CheckBox: React.FunctionComponent<CheckBoxProps> = ({
  label,
  value,
  error,
  checked,
  disabled,
  onChange
}) => {
  const checkmarkStyle = classNames("checkmark", { error: error });
  const containerStyle = classNames("container", {
    boldText: checked,
    disabled: disabled
  });

  return (
    <div>
      <label className={containerStyle}>
        {label}
        <input
          value={value}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={onChange}
        />
        <span className={checkmarkStyle} />
      </label>
    </div>
  );
};

export default CheckBox;
