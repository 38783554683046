import React from "react";
import { Formik, FormikValues } from "formik";
import { useMedia } from "react-use-media";
import Bugsnag from "@bugsnag/js";
import * as Yup from "yup";

import { useAlert } from "modules/context/AlertProvider";
import { useUser } from "modules/context/UserProvider";
import TextInput from "components/TextInput";
import Button from "components/Button";

import { handlePreventDefault } from "modules/utils";
import apiCall, { Endpoints } from "modules/api";
import "./styles.scss";

interface Props {
  onClose?: () => void;
}

const validationSchema: any = Yup.object().shape({
  email: Yup.string()
    .email("Enter a valid email address")
    .required("Enter a valid email address"),
  message: Yup.string()
    .min(20, "Text has to be longer than 20 characters")
    .max(200, "Text has to be less than 200 characters")
    .required("Enter a message"),
});

export const ContactUsButton = () => {
  const { showContactUs } = useAlert();

  return (
    <div className="user-not-found-buttons-wrapper">
      <Button
        icon="arrowRight"
        onClick={showContactUs}
        overrideButtonClass="button submit"
        type="rounded"
      >
        Contact Us
      </Button>
    </div>
  );
};

const ContactUs: React.FunctionComponent<Props> = ({ onClose }) => {
  const isWide = useMedia({ minWidth: 769 });
  const { user } = useUser();
  const [isSubmitted, setSubmitted] = React.useState(false);
  const [isSuccess, setSuccess] = React.useState<boolean | null>();

  const handleSubmit = async (data: FormikValues) => {
    setSubmitted(true);

    const { email, message } = data;

    let form = new FormData();

    const userInfo = {
      id: user.id,
      name: user.id,
      email,
    };
    const app = {
      page: "ContactUs",
      build: "Web application",
      version: "1.0.0",
    };

    const meta = {
      timestamp: Math.floor(Date.now() / 1000),
    };
    form.append("message", JSON.stringify(message));
    form.append("user", JSON.stringify(userInfo));
    form.append("app", JSON.stringify(app));
    form.append("meta", JSON.stringify(meta));

    try {
      await apiCall.post(Endpoints.feedback, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      Bugsnag.notify(error);
    } finally {
      setSubmitted(false);
    }
  };

  return (
    <div className="contact-us" onClick={onClose}>
      <div className="contact-us-container" onClick={handlePreventDefault}>
        <Formik
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          initialValues={{ email: "", message: "" }}
        >
          {({ values, errors, touched, handleSubmit, handleChange }) => (
            <div className="contact-us-content">
              <div
                className={`contact-us-controls ${
                  isSuccess !== undefined ? "done" : ""
                }`}
              >
                <button className={`button close`} onClick={onClose}>
                  Close
                </button>
                {!isWide && isSuccess === undefined && (
                  <button
                    disabled={isSubmitted}
                    className="button send"
                    onClick={() => handleSubmit()}
                  >
                    Send
                  </button>
                )}
              </div>
              {isSuccess === undefined && (
                <React.Fragment>
                  <p className="title">Contact Us</p>
                  <p className="description">
                    Do you have any questions or feedback? Let us know by
                    filling in the following form or contact us directly at{" "}
                    <a href="mailto:contact@qumata.com">contact@qumata.com</a>.
                    Expect a reply within 24 hours.{" "}
                  </p>

                  <TextInput
                    error={touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    label="Your Email Address"
                    onChange={handleChange("email")}
                    placeholder="loremipsum@gmail.com"
                    type="dark"
                    value={values.email}
                    width="45%"
                  />
                  <p />

                  <TextInput
                    error={touched.message && !!errors.message}
                    helperText={touched.message && errors.message}
                    label="Your Message"
                    multiline={true}
                    onChange={handleChange("message")}
                    placeholder="Send us questions or give us some feedback"
                    type="dark"
                    value={values.message}
                    width="100%"
                  />

                  <div className="send-container">
                    <Button
                      type="rounded"
                      icon="arrowRight"
                      pending={isSubmitted}
                      onClick={handleSubmit}
                      disabled={isSubmitted}
                      overrideButtonClass={`button submit ${
                        Object.values(errors).some((i) => i) ? "" : "active"
                      }`}
                    >
                      Send
                    </Button>
                  </div>
                </React.Fragment>
              )}

              {isSuccess === true && (
                <React.Fragment>
                  <p className="title success">Thank You</p>
                  <p className="description small">
                    Your message has been sent to our team at{" "}
                    <a href="mailto:contact@qumata.com" target="_top">
                      contact@qumata.com
                    </a>
                  </p>
                  <p className="description small">
                    We will respond within 24 hours to {values.email}
                  </p>
                </React.Fragment>
              )}

              {isSuccess === false && (
                <React.Fragment>
                  <p className="title success">We're Sorry</p>
                  <div className="description small">
                    Your message was not delivered. Please try again or contact
                    us directly on:
                    <div>
                      <a href="mailto:contact@qumata.com" target="_top">
                        contact@qumata.com
                      </a>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ContactUs;
