interface Config {
  api: string;
  hjid: string;
  hjsv: string;
  gaTrackingId: string;
  bugsnagId: string;
  branchKey: string;
  feedbackUrl: string;
}

export const config: Config = {
  api: process.env.REACT_APP_API_URL,
  hjid: process.env.REACT_APP_HJ_ID,
  hjsv: process.env.REACT_APP_HJ_SV,
  gaTrackingId: process.env.REACT_APP_GOOGLE_TRACKING_ID,
  bugsnagId: process.env.REACT_APP_BUGSNAG_ID,
  branchKey: process.env.REACT_APP_BRANCH_KEY,
  feedbackUrl: process.env.REACT_APP_FEEDBACK_URL,
};

export enum DeviceId {
  apple = "apple",
  garmin = "garmin",
  google = "google",
  fitbit = "fitbit",
  underarmour = "underarmour",
}

export enum AccessStatus {
  connected = "connected",
  notReceived = "notReceived",
  notEnoughData = "notEnoughData",
  loading = "loading",
  collecting = "collecting",
}

export const externalURLs = {
  privacyPolicy: "https://qumata.com/oneclick-privacy-policy",
  termsAndConditions: "https://qumata.com/oneclick-terms-and-conditions",
};
