import React from "react";

import { DeviceId } from "modules/constants";
import DeviceDataUploading from "screens/DeviceDataUploading";

const GarminUploading: React.FunctionComponent<{}> = () => (
  <DeviceDataUploading deviceId={DeviceId.garmin} />
);

export default GarminUploading;
