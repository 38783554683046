import React from "react";
import classNames from "classnames";
import { FormikErrors } from "formik";

import "./field.scss";

type FormFieldProps = {
  title?: string;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  className?: string;
  measure?: string;
};

const FormField: React.FC<FormFieldProps> = ({
  title,
  error,
  children,
  className
}) => {
  const fieldClass = classNames("form-field", className, {
    "field-error": error
  });

  return (
    <div className={fieldClass}>
      {title && <p className="field-title">{title}</p>}
      {children}
      <p className="field-disclaimer">{error || " "}</p>
    </div>
  );
};

export default FormField;
