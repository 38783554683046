import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import "./styles.scss";

interface Props {
  history: any;
}

const NotFound: React.FunctionComponent<Props> = ({ history }) => {
  const navigate = useNavigate();

  return (
    <div className="not-found-container">
      <div className="form-wrapper">
        <div className="content-container">
          <div className="header">404</div>
          <div className="content">
            Sorry, the page you are looking for could not be found. Please go
            back to the homepage or contact us directly at{" "}
            <a href="mailto:contact@qumata.com" target="_top">
              contact@qumata.com
            </a>
            .
          </div>
          <Button
            onClick={() => navigate("/")}
            type="rounded"
            overrideButtonStyle={{
              border: "1px solid",
              justifyContent: "center",
            }}
          >
            Go Home
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
