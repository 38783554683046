import qs from "qs";
import branch from "branch-sdk";
import Bugsnag from "@bugsnag/js";
import { User } from "typing";
import apiCall, { Endpoints } from "modules/api";
import { config } from "modules/constants";

const {
  localStorage,
  location: { search },
} = window;
export const USER = "oc_user";

/** retrieves user from localStorage
 *
 * @returns user object {id: string, token: string}
 * or null if user not found
 */
export const getInitialUser = (): User | null => {
  try {
    const userString = window.localStorage.getItem(USER);

    return JSON.parse(userString);
  } catch (error) {
    return null;
  }
};

/** retrieves user from query params
 *
 * @returns user object {id: string, token: string}
 * or null if user not found
 */
export const getUserFromQuery = async (): Promise<User | null> => {
  if (!search) return;

  const query = search.replace("?", "");
  let params = qs.parse(query);

  if (
    params.ExternalBroker &&
    params.ExternalBrokerID &&
    params.ExternalID &&
    params.ProviderToken
  ) {
    try {
      const createdUser = await createExternalUser(params);
      const { id, token, redirect } = createdUser;
      localStorage.setItem(USER, JSON.stringify({ id, token, redirect }));
      return { id: id, token: token, redirect: redirect };
    } catch (error) {
      Bugsnag.notify(error);
      return null;
    }
  } else {
    try {
      const queryString = atob(query);
      const { id, token } = qs.parse(queryString);

      if (id && token) {
        localStorage.setItem(USER, JSON.stringify({ id, token }));
        return { id: id.toString(), token: token.toString() };
      }
    } catch (error) {
      Bugsnag.notify(error);
      return null;
    }
  }
};

const createExternalUser = async (params): Promise<User | null> => {
  let stringParams = `ExternalID=${params.ExternalID}`;

  const additionalParams = {
    ExternalBroker: params.ExternalBroker,
    ExternalBrokerID: params.ExternalBrokerID,
    ProviderToken: params.ProviderToken,
    DC1: params.DC1,
    AGE: params.AGE,
    H_CM: params.H_CM,
    H_FT: params.H_FT,
    H_INCHES: params.H_INCHES,
    W_KG: params.W_KG,
    W_ST: params.W_ST,
    W_LB: params.W_LB,
    DD1: params.DD1
  };


  Object.keys(additionalParams).map((parameter: string) =>
    (additionalParams[parameter]) ?
      stringParams = stringParams + `&${parameter}=${additionalParams[parameter]}` :
      null
  );

  const url = config.api + Endpoints.createExternalUser + "?" + stringParams;
  const response = await apiCall.post(url);
  const dataEncoded: string = response.data;
  const dataDecoded = atob(dataEncoded);
  const { id, token, redirect } = qs.parse(dataDecoded);
  return {
    id: id.toString(),
    token: token.toString(),
    redirect: redirect.toString(),
  };
};

type ResultData = {
  data_parsed: {
    ["username"]?: string;
    ["token"]?: string;
    ["server"]?: string;
  };
};

/** Make promise with callback func
 *
 * @returns params if app open from deep link
 *
 */
const getBranchData = (): Promise<ResultData> =>
  new Promise((resolve, reject) => {
    const cb = (err: string, results: ResultData) =>
      err ? reject(err) : resolve(results);

    return branch.data(cb);
  });

/** Retrieves user from deep link params with branch io sdk
 *
 * @returns user object {id: string, token: string}
 * or null if user not found
 */
export const getUserFromLink = async (): Promise<User | null> => {
  try {
    const { data_parsed } = await getBranchData();
    const username = data_parsed?.["username"];
    const token = data_parsed?.["token"];
    const server = data_parsed?.["server"]
      ? `https://${data_parsed?.["server"]}`
      : "";

    if (!username || !token) {
      return null;
    }

    localStorage.setItem(USER, JSON.stringify({ id: username, token, server }));

    return { id: username, token };
  } catch (error) {
    return null;
  }
};

export const destroyUser = () => {
  localStorage.removeItem(USER);
};
