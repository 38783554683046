import React from "react";

import ConnectDeviceScreen from "screens/ConnectDevice";
import { DeviceId } from "modules/constants";

const ConnectUnderarmourScreen: React.FunctionComponent<{}> = () => (
  <ConnectDeviceScreen deviceId={DeviceId.underarmour} />
);

export default ConnectUnderarmourScreen;
