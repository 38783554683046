import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Bugsnag from "@bugsnag/js";

import Button, { ButtonType } from "components/Button";
import { useUser } from "modules/context/UserProvider";
import { handlePreventDefault } from "modules/utils";
import { RoutesPaths } from "modules/routes";
import Pending from "components/Pending";

import "./styles.scss";
import apiCall, { Endpoints } from "modules/api";

interface Props {
  device?: string;
  onClose?: () => void;
  callback?: () => void;
}

const RevokeConsent: React.FunctionComponent<Props> = ({
  device,
  onClose,
  callback,
}) => {
  const { user } = useUser();
  const navigate = useNavigate();

  const [pending, setPending] = useState(false);

  const handleDisconnect = async (device) => {
    if (!user?.token) return;

    try {
      if (device === "apple") {
        navigate(RoutesPaths.appleHealthTutorial13);
      } else {
        setPending(true);
        await apiCall.delete(`${Endpoints.authProvider}${device}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: user.token,
          },
        });

        await callback();
        onClose();
      }
    } catch (error) {
      Bugsnag.notify(error);
    } finally {
      setPending(false);
    }
  };

  return (
    <div className="about-data-collection" onClick={onClose}>
      <div
        className="about-data-collection-container"
        onClick={handlePreventDefault}
      >
        <div className="about-data-collection-content">
          <div className="controls">
            <button className="button close" onClick={onClose}>
              Close
            </button>
          </div>
          <div className="title">
            <span>Revoking Consent?</span>
          </div>
          <div className="description">
            <p>
              Are you sure you want to disconnect your data source? We won't be
              able to track your activity data or create your assessments
              without this information.
            </p>

            <div className="buttons-container">
              <Button type={ButtonType.cancel} onClick={onClose}>
                Cancel
              </Button>

              {pending ? (
                <Pending />
              ) : (
                <Button
                  type={ButtonType.text}
                  onClick={() => handleDisconnect(device)}
                >
                  Disconnect
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevokeConsent;
