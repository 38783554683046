import React, { useEffect, useState } from "react";
import ContactUs from "components/Modals/ContactUs";
import { useUser } from "modules/context/UserProvider";
import { useNavigate } from "react-router";

import { useCollectionStatusQuery, useProfileQuery } from "modules/utils/hooks";
import { useAlert } from "modules/context/AlertProvider";
import Button, { ButtonType } from "components/Button";
import apiCall, { Endpoints } from "modules/api";
import Layout from "components/Layout/Layout";
import { RoutesPaths } from "modules/routes";

import classes from "./WellDone.module.scss";
import Bugsnag from "@bugsnag/js";

interface Props {
  history: any;
}

const DisconnectContent = ({
  onContinue,
  onCancel,
}: {
  onContinue: () => void;
  onCancel: () => void;
}) => (
  <React.Fragment>
    <p>
      You have a right to revoke your consent to connect your data source if you
      changed your mind and do not want to participate in the digital
      underwriting pilot test.
    </p>
    <p>
      However, this means you can not anymore receive your incentive from your
      insurance company.
    </p>
    <p>If you wish to proceed select the “Disconnect” button below.</p>
    <div className={classes["button-container"]}>
      <div className={classes["left-button"]}>
        <Button type={ButtonType.cancel} onClick={onCancel}>
          Cancel
        </Button>
      </div>

      <Button type={ButtonType.text} onClick={onContinue}>
        Disconnect
      </Button>
    </div>
  </React.Fragment>
);

const WellDone: React.FunctionComponent<Props> = () => {
  const { data, refetch } = useCollectionStatusQuery();
  const { data: profile } = useProfileQuery();
  const { user, destroy } = useUser();
  const { show, close } = useAlert();
  const navigate = useNavigate();

  const [showContactUs, toggleContactUs] = useState(false);
  const [tracked, setTracked] = useState(false);
  const [pending, setPending] = useState(false);

  const hasEnoughData =
    data && Object.values(data).some((status) => status.has_enough_data);

  useEffect(() => {
    const trackScreen = async () => {
      if (tracked) return;
      setPending(true);

      try {
        if (typeof user.token !== "string") throw new Error("token");

        const headers = {
          "Content-Type": "application/json",
          Authorization: user.token,
        };

        await apiCall.post(Endpoints.trackWellDone, {}, { headers });

        setTracked(true);
        setPending(false);
      } catch (error) {
        Bugsnag.notify(error);
      }
    };

    if (!profile) {
      return;
    }

    if (
      user &&
      !tracked &&
      !pending &&
      !profile?.waiting_data_screen_visited_at
    )
      trackScreen();

    const interval = setInterval(async () => {
      await refetch();

      if (hasEnoughData) navigate(RoutesPaths.thankYou);
    }, 15 * 1000);

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [
    user,
    tracked,
    refetch,
    pending,
    profile,
    navigate,
    hasEnoughData,
    profile?.waiting_data_screen_visited_at,
  ]);

  const handleOk = () => {
    destroy();
    navigate("/");
  };

  const onDisconnectHandle = () =>
    show(
      "Disconnect your device",
      <DisconnectContent
        onContinue={() => {
          close();
          navigate(RoutesPaths.chooseDevices);
        }}
        onCancel={() => close()}
      />,
    );

  return (
    <Layout progressBarPercent={100}>
      <React.Fragment>
        <div className={classes["thank-you-container"]}>
          <div className={classes["form-wrapper"]}>
            <div className={classes["content-container"]}>
              <div className={classes["text-container"]}>
                <div className={classes["header"]}>Well Done</div>
                <div className={classes["content"]}>
                  Your digital device is now connected and you just need to wait
                  for some more days, 1 to 13 days to collect enough quality
                  data.
                </div>
                <div className={classes["content"]}>
                  Please don't disconnect your data source or delete this app in
                  the meantime.
                </div>
                <div className={classes["content"]}>
                  Please check back here in a few days.
                </div>
              </div>

              <div className={classes["bottom-container"]}>
                <Button icon="arrowRight" onClick={handleOk} type="rounded">
                  Got it
                </Button>
                <div className={classes["small-content"]}>
                  If you don't want to participate in the POC press{" "}
                  <span onClick={onDisconnectHandle}>disconnect</span>.
                </div>
              </div>
            </div>
          </div>
        </div>
        {showContactUs && <ContactUs onClose={() => toggleContactUs(false)} />}
      </React.Fragment>
    </Layout>
  );
};

export default WellDone;
