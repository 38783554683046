import React from "react";

import ContactUs, { ContactUsButton } from "components/Modals/ContactUs";

import { handlePreventDefault } from "modules/utils";
import Button from "components/Button";
import "./_styles.scss";

interface Props {
  onClose?: () => void;
}

type AlertContextType = {
  visible: boolean;
  show: (title: string, description: React.ReactElement) => void;
  close: () => void;
  showContactUs: () => void;
};

type DataType = {
  title: string;
  description: React.ReactElement;
} | null;

export const DefaultErrorTitle = "Oops, an Error Occurred!";
export const DefaultError = () => (
  <>
    <p>Sorry, something went wrong. Please try again in a few moments.</p>
    <p>
      Please feel free to contact us using the contact button on this page or
      write to us directly at{" "}
      <a href="mailto:contact@qumata.com">contact@qumata.com</a>
    </p>
    <ContactUsButton />
  </>
);

export const UploadingDataErrorTitle = "Can not upload your data!";
export const UploadingDataError = ({ onClose }: { onClose: () => void }) => (
  <div className="popup-content">
    <p>
      There is a problem in uploading your data from your device. This could be
      because your provider has difficulty sending your data.
    </p>
    <p>Please try again in 10 minutes.</p>
    <p>
      In he meanwhile we will keep requesting your activity data. Your
      information will remain secure.
    </p>

    <Button type="rounded" overrideButtonClass={"try-again"} onClick={onClose}>
      Try again
    </Button>
  </div>
);

export const AlertContext = React.createContext<AlertContextType>(null);

const AlertProvider: React.FC<Props> = ({ onClose, children }) => {
  const [formVisible, setFormVisible] = React.useState(false);
  const [visible, showAlert] = React.useState(false);
  const [data, setData] = React.useState<DataType>();

  const showContactUs = () => {
    close();
    setFormVisible(true);
  };

  const show = (title: string, description: React.ReactElement): void => {
    setData({ title, description });

    showAlert(true);
  };

  const close = () => {
    showAlert(false);
    setData(null);
  };

  return (
    <AlertContext.Provider value={{ visible, show, showContactUs, close }}>
      {children}
      {visible ? (
        <div className="user-not-found" onClick={onClose}>
          <div
            className="user-not-found-container"
            onClick={handlePreventDefault}
          >
            <div className="user-not-found-content">
              <div className="controls">
                <button className="button close" onClick={close}>
                  Close
                </button>
              </div>
              <div className="title">{data.title}</div>
              <div className="description">{data.description}</div>
            </div>
          </div>
        </div>
      ) : null}

      {formVisible && <ContactUs onClose={() => setFormVisible(false)} />}
    </AlertContext.Provider>
  );
};

export const useAlert = () => {
  const context = React.useContext(AlertContext);

  return context;
};

export default AlertProvider;
