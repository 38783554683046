import React from "react";
import classNames from "classnames";

import { images } from "assets/images";
import "./styles.css";

type Icons = keyof typeof images;

export enum ButtonType {
  text = "text",
  cancel = "cancel",
  rounded = "rounded",
  underlined = "underlined"
}

export interface ButtonProps {
  icon?: Icons;
  pending?: boolean;
  disabled?: boolean;
  isSubmit?: boolean;
  onClick?: () => void;
  overrideTextClass?: string;
  overrideTextStyle?: Object;
  children?: React.ReactNode;
  overrideButtonClass?: string;
  overrideButtonStyle?: Object;
  type: keyof typeof ButtonType;
}

const Button: React.FunctionComponent<ButtonProps> = ({
  icon,
  type,
  onClick,
  pending,
  children,
  disabled,
  isSubmit,
  overrideTextStyle,
  overrideTextClass,
  overrideButtonClass,
  overrideButtonStyle
}) => {
  const buttonStyle = classNames(
    {
      "button-rounded": type === ButtonType.rounded,
      "button-underlined": type === ButtonType.underlined,
      "button-text": type === ButtonType.text,
      "button-cancel": type === ButtonType.cancel,
      "button-disabled": disabled
    },
    overrideButtonClass
  );

  const textButtonStyle = classNames(
    {
      "button-rounded-text":
        type === ButtonType.rounded || type === ButtonType.cancel,
      "button-underlined-text": type === ButtonType.underlined,
      "button-text-text": type === ButtonType.text
    },
    overrideTextClass
  );

  return (
    <button
      className={buttonStyle}
      disabled={disabled}
      onClick={onClick}
      style={overrideButtonStyle}
      type={isSubmit ? "submit" : "button"}
    >
      <div className={textButtonStyle} style={overrideTextStyle}>
        {children}
      </div>
      {pending && (
        <img alt="button icon" className="button-image" src={images.pending} />
      )}
      {icon && !pending && (
        <img alt="button icon" className="button-image" src={images[icon]} />
      )}
    </button>
  );
};

export default Button;
