import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  useCollectionStatusQuery,
  useProfileMutation,
  useProfileQuery,
} from "modules/utils/hooks";
import { conditions, conditionsValues } from "./helper";
import Layout from "components/Layout/Layout";
import CheckBox from "components/Checkbox";
import { RoutesPaths } from "modules/routes";
import Button from "components/Button";
import "./styles.scss";
import {
  useAlert,
  DefaultError,
  DefaultErrorTitle,
} from "modules/context/AlertProvider";
import Bugsnag from "@bugsnag/js";

const Conditions: React.FunctionComponent<{}> = () => {
  const navigate = useNavigate();
  const [isSubmitted, setSubmitted] = useState(false);
  const { mutate } = useProfileMutation();
  const { data: profileData, isLoading: isProfileLoading } = useProfileQuery();
  const [valuesPresetted, setValuesPresetted] = useState(false);
  const [initialValues, setInitialValues] = useState(conditionsValues);
  const { show, close } = useAlert();
  const { data: providersStatus } = useCollectionStatusQuery();

  const hasEnoughData =
    providersStatus &&
    Object.values(providersStatus).some((status) => status.has_enough_data);

  const submit = async (values) => {
    setSubmitted(true);

    try {
      const conditions: string[] = Object.keys(conditionsValues).filter(
        (key) => initialValues[key],
      );

      if (!conditions.some((i) => i)) {
        setSubmitted(false);

        return show(
          "Select conditions!",
          <div className="error-wrapper">
            <div className="error-message-container">
              <p>
                Please select any conditions you have or switch off conditions
                button.
              </p>
            </div>
            <Button type="rounded" onClick={close}>
              Select
            </Button>
          </div>,
        );
      }

      if (conditions.includes("none")) conditions.length = 0;

      await mutate({ conditions });

      if (hasEnoughData) {
        navigate(RoutesPaths.thankYou);
      } else {
        navigate(RoutesPaths.wellDone);
      }
    } catch (error) {
      Bugsnag.notify(error);
      show(DefaultErrorTitle, <DefaultError />);
    }

    setSubmitted(false);
  };

  useEffect(() => {
    if (valuesPresetted) return;

    if (!isProfileLoading && profileData) {
      const conditionsToUpdate = Object.assign({}, initialValues);

      for (let key in initialValues) {
        if (profileData.conditions.includes(key))
          conditionsToUpdate[key] = true;
      }

      if (JSON.stringify(conditionsToUpdate) !== JSON.stringify(initialValues))
        setInitialValues(conditionsToUpdate);

      setValuesPresetted(true);
    }
  }, [isProfileLoading, profileData, initialValues, valuesPresetted]);

  const changeValue = (key: string) => {
    const conditionsToUpdate = Object.assign({}, initialValues);

    if (key === "none") {
      for (let item in conditionsToUpdate) {
        if (item !== "none") conditionsToUpdate[item] = false;
      }

      conditionsToUpdate[key] = !conditionsToUpdate[key];
    } else {
      conditionsToUpdate[key] = !conditionsToUpdate[key];
      if (conditionsToUpdate.none === true) conditionsToUpdate.none = false;
    }

    setInitialValues(conditionsToUpdate);
  };

  return (
    <Layout progressBarPercent={80}>
      <div className="profile">
        <>
          <div className="profile-header" style={{ marginBottom: 20 }}>
            Pre-Existing medical conditions
          </div>

          <div className="subHeader">
            Please select if you have been diagnosed with any of these medical
            conditions?
          </div>
        </>
        <Formik
          onSubmit={(values) => submit(values)}
          initialValues={{
            ...conditionsValues,
          }}
        >
          {({
            values: { ...conditionsValues },
            handleChange,
            handleSubmit,
          }) => {
            return (
              <>
                <>
                  {Object.keys(initialValues).map((key) => (
                    <div className="section-row" key={key}>
                      <CheckBox
                        checked={initialValues[key]}
                        label={conditions[key]}
                        onChange={() => {
                          changeValue(key);
                          handleChange(key);
                        }}
                      />
                    </div>
                  ))}
                </>
                <div className="buttons-wrapper" style={{ marginTop: "30px" }}>
                  <Button
                    disabled={isSubmitted || !valuesPresetted}
                    icon="arrowRight"
                    onClick={handleSubmit}
                    pending={isSubmitted}
                    type="rounded"
                  >
                    Continue
                  </Button>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
};

export default Conditions;
