import React, { useEffect, useState } from "react";
import classNames from "classnames";
import QRCode from "qrcode.react";
import {
  useCollectionStatusQuery,
  useProviderStatusQuery,
} from "modules/utils/hooks";
import Button, { ButtonType } from "components/Button";
import { useUser } from "modules/context/UserProvider";
import { RoutesPaths } from "modules/routes";
import Layout from "components/Layout/Layout";
import { useNavigate } from "react-router-dom";
import Pending from "components/Pending";
import { Endpoints } from "modules/api";
import { config, DeviceId } from "modules/constants";
import { images } from "assets/images";
import "./styles.scss";

const AppleHealthConsent: React.FunctionComponent<{}> = () => {
  const { user } = useUser();
  const { data } = useCollectionStatusQuery();
  const { data: appleData } = useProviderStatusQuery(DeviceId.apple, {
    refetchInterval: 5000,
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [deeplink, setDeepLink] = useState("");

  useEffect(() => {
    if (!deeplink && user && !loading) {
      const requestDeeplink = async (): Promise<void> => {
        try {
          if (typeof user.token !== "string") throw new Error("token");
          setLoading(true);

          const url = `${config.api}${Endpoints.deeplink}`;

          const headers = {
            Authorization: user.token,
            "Content-Type": "application/json",
          };

          const response = await fetch(url, { headers });
          const res = await response.json();

          setDeepLink(res.deeplink);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      };

      requestDeeplink();
    }
  }, [deeplink, loading, user]);

  const connectContainerStyle = classNames("connect-device", {
    connected: data?.find((i) => i.name === DeviceId.apple),
  });

  useEffect(() => {
    if (!appleData) return;

    if (appleData.connected === true) {
      navigate(RoutesPaths.chooseDevices);
    }
  }, [appleData, navigate]);

  return (
    <Layout progressBarPercent={60}>
      <div className={connectContainerStyle}>
        <div className="header-wrapper">
          <div className="connect-device-header">Apple Health Connection</div>
          <img
            alt="logo"
            className="header-logo"
            src={images.appleHealthLogo}
          />
        </div>
        <div className="description">
          You need to download a mobile app in order to share your activity data
          from your AppleHealth app with us safely.
        </div>

        <div className="description qr">
          Please scan the QR code with your phone in order to download and open
          our OneClick app.
        </div>

        <div className="description deeplink">
          If you are viewing this page on your phone, please press the link
          below to download and open OneClick mobile app.
        </div>

        <div>
          {loading ? (
            <Pending />
          ) : (
            <div className="qr-container">
              <div className="qr code">
                {!!deeplink && <QRCode value={deeplink} size={250} />}
              </div>
              <a className="deeplink" href={deeplink}>
                Download the OneClick app
              </a>
            </div>
          )}
        </div>

        <div className="description">
          Please continue the rest of the process on the OneClick mobile app. In
          case of any problem use the contact icon on the top right corner to
          write to us.
        </div>

        {user?.redirect && (
          <div className="alertContainer">
            <div className="alertTitle">Please don't close this web page!</div>
            <div className="alertMessage">
              After you finished the process on your mobile app, this page will
              be updated to let you continue your survey
            </div>
          </div>
        )}

        <div className="connect-device-buttons-wrapper-apple">
          <div className="left-button">
            <Button
              type={ButtonType.rounded}
              onClick={() => navigate(RoutesPaths.home)}
            >
              I opened the app
            </Button>
          </div>

          <Button
            type={ButtonType.underlined}
            icon="arrowRight"
            onClick={() => navigate(RoutesPaths.chooseDevices)}
          >
            Try another device
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default AppleHealthConsent;
