import React from "react";
import Button, { ButtonType } from "components/Button";
import { AccessStatus, DeviceId } from "modules/constants";
import { useNavigate } from "react-router-dom";
import { RoutesPaths } from "modules/routes";

import DeviceHeader from "../DeviceHeader/DeviceHeader";
import { mapIdToDevice } from "screens/ConnectDevice";
import classes from "./ConnectedDevice.module.scss";
import { useUser } from "modules/context/UserProvider";
import changeRedirectURL from "modules/utils/redirect";
import { useCollectionStatusQuery } from "modules/utils";
import Bugsnag from "@bugsnag/js";
import apiCall, { Endpoints } from "modules/api";

const ConnectedDevice = ({ device }: { device: DeviceId }) => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { data: providersStatus } = useCollectionStatusQuery();

  const hasEnoughData =
    providersStatus &&
    Object.values(providersStatus).some((status) => status.has_enough_data);

  const handleNext = async () => {
    if (user.redirect) {
      const changedRedirectURL = changeRedirectURL(
        user.redirect,
        hasEnoughData,
      );

      try {
        if (typeof user.token !== "string") throw new Error("token");

        const headers = {
          "Content-Type": "application/json",
          Authorization: user.token,
        };

        await apiCall.post(
          hasEnoughData ? Endpoints.trackThankYou : Endpoints.trackWellDone,
          {},
          { headers },
        );
      } catch (error) {
        console.log(error);
        Bugsnag.notify(error);
      }

      window.location.replace(changedRedirectURL);
    } else {
      navigate(RoutesPaths.chooseDevices);
    }
  };

  return (
    <>
      <DeviceHeader device={device} status={AccessStatus.connected} />
      <div className={classes["small-content"]}>
        Your {mapIdToDevice[device].title} app is connected and we're received
        your activity data.
      </div>

      <Button icon="arrowRight" type={ButtonType.rounded} onClick={handleNext}>
        Next
      </Button>
    </>
  );
};

export default ConnectedDevice;
