import React from "react";
import { DeviceDataInterface } from "./DeviceItem";
import "./styles.css";

const DeviceItemStatus: React.FunctionComponent<{
  status: DeviceDataInterface;
}> = ({ status }) => {
  if (!status) {
    return null;
  }

  const getStatus = () => {
    switch (true) {
      case status.is_receiving_data && status.has_enough_data:
        return <div className="status connected">Connected</div>;

      case status.is_receiving_data:
        return (
          <div className="status uploading">Connected, Uploading data</div>
        );
      case !status.is_receiving_data:
        return <div className="status no-consent">No consent</div>;
      default:
        return <div className="status">""</div>;
    }
  };

  return <div className="status-container">{getStatus()}</div>;
};

export default DeviceItemStatus;
