import React from "react";

import ConnectDeviceScreen from "screens/ConnectDevice";
import { DeviceId } from "modules/constants";

const FitbitUploading: React.FunctionComponent<{}> = () => (
  <ConnectDeviceScreen deviceId={DeviceId.fitbit} />
);

export default FitbitUploading;
